<template>
  <div class="pb-5">
    <!-- Call result, Bao Value, Date of the call, call duration, counterparts-->
    <div class="row">
      <!-- Counterparts -->
      <div class="col-4 border-right pr-4">
        <div id="no-counterpart-selected-202012241523"
             v-if="!counterpartsAvailable"
        >
          {{ staticText.noCounterpartsSelected }}
        </div>

        <div v-else>
          <b-list-group class="counterparts-block">
            <b-list-group-item
              v-for="(counterpart, index) in summary.counterparts"
              :key="index"
              class="my-1"
            >
              <counterpart-display-cell
                :id="'counterpart-display-202103221659-'+index"
                :name="counterpart.name"
                :type="counterpart.type"
                :direct_link="counterpart.direct_link"
                :crm_service="counterpart.crm_service"
              ></counterpart-display-cell>
            </b-list-group-item>
          </b-list-group>
        </div>
      </div>

      <!-- Call Date+Time and Duration -->
      <div class="form-group col-4 border-right px-4">
        <!-- Call Date Time -->
        <div class="input-wrapper mb-2">
          <input :id="'conversation-date-time-'+summary.id"
                 :value="summary.date+' '+summary.time"
                 disabled
                 class="w-100"
          >
          <span class="input-label">
            {{ staticText.conversationDateTimeLabel }}
          </span>
        </div>

        <!-- Call Duration -->
        <div v-if="summary.duration && summary.duration > 0"
             class="input-wrapper"
        >
          <input :id="'duration-'+summary.id"
                 :value="convertMillisecondsToHHMMSS(summary.duration)"
                 class="w-100"
                 disabled
          />
          <span class="input-label">
            {{ staticText.callDurationLabel }}
          </span>
        </div>
      </div>
      <div class="col-4 px-4">
        <!-- Call Result -->
        <div v-if="canUseCallResult && summary.result"
             class="input-wrapper mb-2"
        >
          <input :id="'call-result-'+summary.id"
                 disabled
                 :value="summary.result"
                 class="w-100"
          >
          <span class="input-label">
            {{ staticText.conversationResultLabel }}
          </span>
        </div>

        <!-- bao Value -->
        <div class="col-12">
          <div v-if="baoValueAvailable"
               class="form-group"
          >
            <bao-value-popover :bao-values="summary.bao_values"></bao-value-popover>
          </div>
        </div>

        <!-- Call Transcript and Text-Match data -->
        <div v-if="!!callTranscriptData"
             class="table p-2"
        >
          <real-time-assistant-arc-pie
            v-if="isValidNumber(callTranscriptData.talking_share)"
            :label="staticText.talkingShareLabel"
            label-placement="right"
            :value="callTranscriptData.talking_share"
            :size="40"
          ></real-time-assistant-arc-pie>
          <real-time-assistant-arc-pie
            v-if="isValidNumber(callTranscriptData.talking_speed)"
            :label="staticText.talkingSpeedLabel"
            label-placement="right"
            :value="callTranscriptData.talking_speed"
            :inner-text="'' + callTranscriptData.talking_speed.toFixed(0)"
            :total="220"
            :size="40"
          ></real-time-assistant-arc-pie>
          <real-time-assistant-arc-pie
            v-if="isValidNumber(callTranscriptData.text_similarity)"
            :label="staticText.textSimilarityLabel"
            label-placement="right"
            :value="callTranscriptData.text_similarity"
            :size="40"
          ></real-time-assistant-arc-pie>
        </div>
      </div>
    </div>

    <!-- Call Flow Summary -->
    <div>
      <b-tabs class="w-100 p-2">
        <!-- Call Text Summary -->
        <b-tab :title="staticText.conversationTextSummaryLabel" active>
          <b-form-textarea id="summary_textarea-010820211343"
                           :value="textSummary"
                           :disabled="false"
                           rows="5"
                           max-rows="200000">
          </b-form-textarea>
        </b-tab>

        <!-- Call Table Summary -->
        <b-tab :title="staticText.conversationSummaryLabel">
          <call-summary-table
            :call-transcript-data="callTranscriptData"
            :summary="summary"
          ></call-summary-table>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import RealTimeAssistantArcPie from "@/apps/call/RealTimeAssistantComponents/RealTimeAssistantArcPie"
import CallSummaryTable from "@/apps/call_history/CallSummaryComponents/CallSummaryTable"
import BaoValuePopover from "@/apps/baovalue/BaoValuePopover"
import CounterpartDisplayCell from "@/apps/call/CounterpartDisplayCell"
import { baoDelayService } from "@/apps/call"

export default {
  name: "CallSummaryPrintContent",
  components: {
    BaoValuePopover,
    CounterpartDisplayCell,
    RealTimeAssistantArcPie,
    CallSummaryTable
  },
  props: {
    summary: {
      type: Object,
      required: true
    },
    textSummary: {
      type: String,
      required: false
    },
    callTranscriptData: {
      type: Object,
      default: null,
      required: false
    }
  },
  data: function () {
    return {
      staticTextDefault: {
        noCounterpartsSelected: "No Counterpart selected.",
        conversationResultLabel: "Conversation Result",
        conversationDateTimeLabel: "Conversation Date & Time",
        callDurationLabel: "Duration",
        talkingShareLabel: "Talking Share",
        talkingSpeedLabel: "Tempo (WPM)",
        textSimilarityLabel: "Text-Match",
        conversationSummaryLabel: "Call Feedback",
        conversationTextSummaryLabel: "Text Summary"
      }
    }
  },
  computed: {
    ...mapGetters({
      canUseBaoValue: "auth/canUseBaoValue",
      canUseCallResult: "auth/canUseCallResult",
      canUseSpeechRecognition: "auth/canUseSpeechRecognition"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    counterpartsAvailable () {
      return !!this.summary.counterparts && this.summary.counterparts.length > 0
    },
    baoValueAvailable () {
      return this.canUseBaoValue && this.summary.bao_values && this.summary.bao_values.length > 0
    }
  },
  methods: {
    convertMillisecondsToHHMMSS (milliseconds) {
      return baoDelayService.methods.convertMillisecondsToHHMMSS(milliseconds)
    }
  }
}

</script>
<style scoped lang="scss">

p {
  white-space: normal
}

.counterparts-block .list-group-item {
  padding: 18px 24px;
  background-color: $white;
  opacity: 0.8;
  box-shadow: 6px 8px 20px rgba(179, 173, 159, 0.12);
  border: none;
  border-radius: 12px;
}
</style>

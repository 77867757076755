import Vue from "vue"
import axios from "axios"
import { typeServices } from "@/apps/call"
import store from "@/store"
import { caseInsensitiveLooseEqual } from "@/apps/talkscript/components/utils"

export const ANSWER_STATUS = {
  OK: "Answers are available",
  NO_RESULTS: "No Results",
  UNEXPECTED_ERROR: "An unexpected error occurred while fetching answers from AI.",
  NO_KEYS_ERROR: "No Questions to ask AI",
  NO_TRANSCRIPT_ERROR: "No transcript available"
}

// Factory: Create handlers for different item types
class ItemHandlerFactory {
  static createHandler (itemType) {
    if (typeServices.isCrmLink(itemType)) {
      return new CrmLinkHandler()
    } else if (typeServices.isQuestion(itemType)) {
      return new QuestionItemHandler()
    }
    return null
  }
}

// Strategy: Define handlers for different types of items
class CrmLinkHandler {
  getKeys (items) {
    return items.flatMap(crmItem =>
      crmItem.linked_field.field_definition.map(def => ({
        question_id: def.id,
        key: def.label,
        value_format: this.getCrmFieldValueType(def),
        can_have_multiple_values: def.fieldType === "checkbox",
        possible_values: this.getPossibleAnswerChoices(def)
      }))
    )
  }

  getPossibleAnswerChoices (def) {
    return def.options && def.options.length > 0 ? def.options.map(option => option.label) : null
  }

  extractAnswers (callItem, results) {
    return callItem.linked_field.field_definition.flatMap(fieldDef => {
      const detectedAnswer = results.find(result => caseInsensitiveLooseEqual(result.question_id, fieldDef.id))
      if (detectedAnswer && detectedAnswer.value) {
        if (typeServices.shouldUseDropdown(fieldDef.fieldType)) {
          return this.getMatchingValue(detectedAnswer.value, fieldDef.options, fieldDef.id) || []
        } else {
          return { question_id: detectedAnswer.question_id, text: detectedAnswer.value.toString() }
        }
      }
      return []
    }).filter(ans => ans && ans.text)
  }

  getMatchingValue (detectedAnswers, answerOptions, questionId) {
    if (Array.isArray(detectedAnswers) && detectedAnswers.length > 1) {
      const matchedValues = detectedAnswers.map(item => getMatch(item, answerOptions)).filter(Boolean)
      return matchedValues.length
        ? matchedValues.map(match => ({
          question_id: questionId,
          text: match.value,
          multiselect: true
        }))
        : null
    } else {
      const match = getMatch(detectedAnswers, answerOptions)
      return match ? { question_id: questionId, text: match.value } : null
    }
  }

  getCrmFieldValueType (def) {
    if (def.options && def.options.length > 0) {
      // options are available then answers should be same as option.label
      return null
    }
    const originalType = def.fieldType
    if (typeServices.isText(originalType) || typeServices.shouldUseDropdown(originalType)) return "text"
    if (typeServices.isSingleSelectBox(originalType)) return "boolean"
    if (typeServices.isDate(originalType) || typeServices.isTime(originalType) || typeServices.isDateTime(originalType)) {
      return `${originalType} in ISO Format`
    }
    return originalType
  }

  prepareAnswersToSave (callItem, existingAnswers, detectedAnswers) {
    const mergedArray = [...existingAnswers] // Copy existingAnswers to mergedArray

    detectedAnswers.forEach(detAns => {
      const indexInExistingAnswers = mergedArray.findIndex(existAns => caseInsensitiveLooseEqual(existAns.question_id, detAns.question_id))

      if (indexInExistingAnswers !== -1) {
        // If element with the same question_id is found in existingAnswers
        const existAns = mergedArray[indexInExistingAnswers]

        if (detAns.multiselect && existAns.text !== detAns.text) {
          // Case 1: Keep element of existingAnswers and add element of detectedAnswers
          mergedArray.push(detAns)
        } else if (!detAns.multiselect) {
          // Case 2: Replace element in existingAnswers with element of detectedAnswers
          mergedArray[indexInExistingAnswers] = detAns
        }
      } else {
        // Add elements that do not exist in existingAnswers to mergedArray
        mergedArray.push(detAns)
      }
    })
    return mergedArray
  }
}

class QuestionItemHandler {
  getKeys (items) {
    return items.map(questionItem => ({
      question_id: questionItem.id,
      key: this.getQuestionKeysForItem(questionItem),
      value_format: null,
      can_have_multiple_values: typeServices.isMultiSelectQuestion(questionItem.item_type),
      possible_values: this.getPossibleAnswerChoices(questionItem)
    }))
  }

  getPossibleAnswerChoices (questionItem) {
    return questionItem.answer_choices && questionItem.answer_choices.length > 0
      ? questionItem.answer_choices.map(option => option.label)
      : null
  }

  getQuestionKeysForItem (item) {
    const displayText = item.display_text
    return displayText ? `${item.name} - ${displayText}` : item.name
  }

  extractAnswers (callItem, results) {
    let matchingValues = []
    const detectedAnswer = results.find(result => caseInsensitiveLooseEqual(result.question_id, callItem.id))
    if (detectedAnswer && detectedAnswer.value) {
      matchingValues = this.getMatchingValue(detectedAnswer.value, callItem.answer_choices).filter(ans => ans && ans.text)
    }
    return matchingValues
  }

  getMatchingValue (detectedAnswers, answerOptions) {
    if (Array.isArray(detectedAnswers) && detectedAnswers.length > 1) {
      const matchedValues = detectedAnswers.map(item => getMatch(item, answerOptions)).filter(Boolean)
      return matchedValues.length
        ? matchedValues.map(match => ({
          question_id: match.id,
          text: match.label,
          multiselect: true
        }))
        : []
    } else {
      const match = getMatch(detectedAnswers, answerOptions)
      return match ? [{ question_id: match.id, text: match.label }] : []
    }
  }

  prepareAnswersToSave (callItem, existingAnswers, detectedAnswers) {
    if (typeServices.isSingleSelectQuestion(callItem.item_type)) {
      return detectedAnswers
    }

    // Convert existingAnswers into a Set of ids to track existing question_ids
    const existingIds = new Set(existingAnswers.map(item => item.question_id))

    // Add only elements from detectedAnswers whose question_ids are not in existingAnswers
    return [...existingAnswers, ...detectedAnswers.filter(item => !existingIds.has(item.question_id))]
  }
}

// Main function: orchestrating the detection of AI answers
export async function fillInAIAnswers (transcript, callItems, revertChanges = false) {
  if (!transcript || !transcript.length) {
    return { status: ANSWER_STATUS.NO_TRANSCRIPT_ERROR }
  }
  if (transcript && transcript.length && callItems && callItems.length) {
    const { keysToDetect, consideredCallItems } = getItemsWithAnswerKeys(callItems)
    const uniqueSetOfKeys = removeDuplicateKeys(keysToDetect) // remove duplicates

    if (revertChanges) {
      return revertToPreviousAnswers(consideredCallItems)
    }

    if (uniqueSetOfKeys.length === 0) {
      return { status: ANSWER_STATUS.NO_KEYS_ERROR }
    }

    return await processTranscript(transcript, uniqueSetOfKeys, consideredCallItems)
  }
}

// helper functions
export async function processTranscript (transcript, uniqueSetOfKeys, consideredCallItems) {
  try {
    const results = await detectItemAnswersFromTranscript(transcript, uniqueSetOfKeys)
    const callItemsWithDetectedAnswers = setDetectedAnswersOnCallItems(results, consideredCallItems)
    return areAnswersDetected(results, callItemsWithDetectedAnswers)
      ? { status: ANSWER_STATUS.OK }
      : { status: ANSWER_STATUS.NO_RESULTS }
  } catch (err) {
    console.error(err)
    return { status: ANSWER_STATUS.UNEXPECTED_ERROR }
  }
}

export function areAnswersDetected (results, callItemsWithDetectedAnswers) {
  const atLeastOneAnswerDetected = callItemsWithDetectedAnswers.some(
    callItem => !!callItem.selectedAnswers && callItem.selectedAnswers.length > 0
  )
  return results && results.length && atLeastOneAnswerDetected
}

export const getMatch = (val, options) =>
  options.find(option => !!val && val.toString().match(new RegExp(option.label.toString(), "gi")))

export function removeDuplicateKeys (allKeys) {
  return allKeys.filter((obj, index, self) =>
    index === self.findIndex((o) => caseInsensitiveLooseEqual(o.question_id, obj.question_id))
  )
}

export function getItemsWithAnswerKeys (callItems, keysToDetect = [], consideredCallItems = []) {
  callItems.forEach(item => {
    if (!item.isAdditionalItem) {
      const handler = ItemHandlerFactory.createHandler(item.item_type)
      if (handler) {
        consideredCallItems.push(item)
        keysToDetect.push(...handler.getKeys([item]))
      }
    } else {
      getItemsWithAnswerKeys(item.children, keysToDetect, consideredCallItems)
    }
  })
  return { keysToDetect, consideredCallItems }
}

// Detecting AI answers from transcript
export async function detectItemAnswersFromTranscript (transcript, questions) {
  const url = "/api/calls/auto_detect_answers/"
  const response = await axios.post(url, { transcript, questions })
  return response.data.results
}

// Extract answers from results using appropriate handlers
export function extractDetectedAnswers (callItem, results) {
  const handler = ItemHandlerFactory.createHandler(callItem.item_type)
  return handler ? handler.extractAnswers(callItem, results) : null
}

// Set detected answers on call items
export function setDetectedAnswersOnCallItems (results, callItems) {
  callItems.forEach(callItem => {
    setCallItemAttribute(callItem, "noAnswersFromAI", false) // reset
    const detectedAnswers = extractDetectedAnswers(callItem, results)
    if (detectedAnswers && detectedAnswers.length) {
      const existingAnswers = callItem.call_item && callItem.call_item.answers ? callItem.call_item.answers : []

      // clone existing answers as previousAnswers so that they can be recovered later while reverting change.
      setCallItemAttribute(callItem, "previousAnswers", existingAnswers)

      const answersToSave = getAnswersToSaveForItem(callItem, existingAnswers, detectedAnswers)
      setCallItemAttribute(callItem, "selectedAnswers", answersToSave)
      setCallItemAttribute(callItem, "hasAIAnswers", true)

      handleItemUpdated(callItem, true) // save callItem
    } else {
      setCallItemAttribute(callItem, "noAnswersFromAI", true)
    }
  })
  return callItems
}

export function getAnswersToSaveForItem (callItem, existingAnswers, detectedAnswers) {
  detectedAnswers.forEach(item => {
    item.isAIDetected = true // this allows us to distinguish AI detected answers from existing answers
  })

  const handler = ItemHandlerFactory.createHandler(callItem.item_type)
  return handler ? handler.prepareAnswersToSave(callItem, existingAnswers, detectedAnswers) : null
}

export function revertToPreviousAnswers (callItems) {
  callItems.forEach(callItem => {
    setCallItemAttribute(callItem, "noAnswersFromAI", false)
    setCallItemAttribute(callItem, "selectedAnswers", callItem.previousAnswers || [])
    if (callItem.hasAIAnswers) handleItemUpdated(callItem) // save callItem
    setCallItemAttribute(callItem, "hasAIAnswers", false)
  })
  return { status: ANSWER_STATUS.OK }
}

export function setCallItemAttribute (callItem, attribute, value) {
  Vue.set(callItem, attribute, value)
}

export function handleItemUpdated (callFlowItem, retainPreviousAnswers = false) {
  return store.dispatch("callSummaryStore/handleCallItemChanged", {
    item: callFlowItem,
    callId: store.getters["callSummaryStore/getCallDetails"].id,
    retainPreviousAnswers
  })
}

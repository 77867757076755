<template>
  <div :class="['bao-widget-filter w-100 d-flex']">
    <bao-widget-filter-container
      :label="label"
      label-tag="base-filter-202309081357"
      :hasError="hasError"
      :has-fixed-label="!isTextInputField"
    >
      <input
        v-if="isTextInputField"
        type="text"
        :class="['form-control', {'ai-answer': isAIDetectedValue}]"
        v-model="inputValue"
        :placeholder="placeholder"
        :disabled="disabled"
        @blur="onInputChange"
      >
      <textarea
        v-else
        :class="['form-control', {'ai-answer': isAIDetectedValue}]"
        v-model="inputValue"
        :placeholder="placeholder"
        :disabled="disabled"
        @blur="onInputChange"
      ></textarea>
    </bao-widget-filter-container>
  </div>
</template>

<script>
import BaoWidgetFilterContainer
  from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/Filter/BaoWidgetFilterContainer.vue"

export default {
  name: "InnerLabelInput",
  components: {
    BaoWidgetFilterContainer
  },
  data () {
    return {
      inputValue: null,
      inputValueChanged: false,
      hasError: false
    }
  },
  props: {
    value: {
      type: Object,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isTextInputField: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (val, oldVal) {
        if (val && val !== oldVal) {
          this.inputValue = val.text
        } else this.inputValue = null
      }
    },
    inputValue () {
      this.inputValueChanged = true
    }
  },
  computed: {
    isAIDetectedValue () {
      return !!this.value && !!this.value.isAIDetected
    }
  },
  methods: {
    onInputChange (event) {
      if (this.inputValueChanged) {
        this.$emit("input", event.target.value)
        this.inputValueChanged = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.form-control {
  background-color: $white80;
  padding: 20px 16px 8px;
  color: $slate;
  resize: none;

  &::placeholder {
    color: rgba(11, 17, 93, 0.6) !important;
  }

  &::-ms-input-placeholder { /* Edge 12 -18 */
    color: rgba(11, 17, 93, 0.6) !important;
  }

  &:disabled {
    border: none;
    background-color: rgba(255, 255, 255, 0.4);
    color: rgba(127, 129, 151, 1);
  }
}

</style>

<template>
  <div class="speech-to-text-notes">
    <h3>{{ computedNotesLabel }}</h3>
    <b-form-textarea
      id="additional_notes"
      v-model="notes"
      :placeholder="staticText.notesPlaceholder"
      :disabled="disabled"
      rows="2"
      max-rows="4"
      @blur="$emit('save-notes')"
    />
    <div class="microphone-container">
      <div class="microphone-container2">
        <div
          role="button"
          :class="['microphone', { active: recognitionRunning }]"
          @click="toggleSpeechToText"
        >
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0_16300_6740)" stroke="#7F8197" stroke-linecap="round" stroke-linejoin="round"><path d="M10 .834a2.5 2.5 0 00-2.5 2.5v6.667a2.5 2.5 0 005 0V3.334a2.5 2.5 0 00-2.5-2.5z" fill="#7F8197" stroke-width="2"/><path d="M15.833 8.334v1.667a5.833 5.833 0 01-11.667 0V8.334" stroke-width="2"/><path d="M10 15.834v3.333M6.666 19.166h6.667" stroke-width="1.667"/></g><defs><clipPath id="clip0_16300_6740"><path fill="#fff" d="M0 0h20v20H0z"/></clipPath></defs></svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition
const recognition = SpeechRecognition ? new SpeechRecognition() : false

export default {
  name: "SpeechToTextNotes",
  data () {
    return {
      notes: this.value,
      recognitionRunning: false,
      staticTextDefault: {
        notesLabel: "Notes",
        notesPlaceholder: "Type or speak your notes here"
      }
    }
  },
  props: {
    value: {
      type: String,
      default: "",
      required: false
    },
    notesLabel: {
      type: String,
      default: "",
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    userLanguage () {
      return this.user.language === "de" ? "de-DE" : "en-US"
    },
    computedNotesLabel () {
      return this.notesLabel ? this.notesLabel : this.staticText.notesLabel
    }
  },
  watch: {
    notes (newNote) {
      this.$emit("note-changed", newNote)
    }
  },
  methods: {
    capitalize (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    startRecognition () {
      document.getElementById("additional_notes").focus()
      if (!recognition) {
        const errorMessage =
          "Speech Recognition is not available on this browser. Please use Chrome or Firefox"

        this.showGlobalToast({ status: "error", message: errorMessage })
        return false
      }
      this.recognitionRunning = true

      const that = this

      let initialAdditionalNote
      let currentTranscript = ""
      let lastWordInTranscript = ""

      recognition.onstart = function (event) {
        initialAdditionalNote = that.notes
      }

      recognition.onresult = function (event) {
        const result = event.results[event.results.length - 1]
        let transcript = result[0].transcript
        if (result.isFinal) {
          transcript = that.capitalize(transcript)
          that.notes = initialAdditionalNote ? `${initialAdditionalNote} ${transcript}.` : `${transcript}.`
          currentTranscript = ""
        } else {
          const splitTranscript = transcript.split(" ")
          const lastWordInCurrentTranscript = splitTranscript[splitTranscript.length - 1]
          if (lastWordInTranscript !== lastWordInCurrentTranscript) {
            lastWordInTranscript = lastWordInCurrentTranscript
            currentTranscript += ` ${lastWordInCurrentTranscript}`
          }
          that.notes = `${initialAdditionalNote} ${that.capitalize(currentTranscript)}`
        }
      }

      recognition.onend = function (event) {
        if (that.recognitionRunning) {
          // keep it going.
          recognition.start()
        }
      }

      // start recognition
      recognition.continuous = false
      recognition.interimResults = true
      recognition.lang = that.userLanguage
      recognition.maxAlternatives = 1
      recognition.start()
    },
    endRecognition () {
      this.recognitionRunning = false
      recognition.stop()
    },
    toggleSpeechToText () {
      this.recognitionRunning ? this.endRecognition() : this.startRecognition()
    }
  },
  beforeDestroy () {
    if (this.recognitionRunning) this.endRecognition()
    this.$emit("save-notes")
  }
}
</script>

<style lang="scss" scoped>
.speech-to-text-notes {
  border-top: 1px solid $black06;
  padding: 8px;
  position: relative;
  @include media-breakpoint-up(md) {
    padding: 8px 16px 16px;
  }
  h3 {
    font-size: 12px;
    line-height: 13px;
    font-weight: 700;
    color: $slate;
    margin-bottom: 8px;
    @include media-breakpoint-up(md) {
      font-size: 16px !important;
      line-height: 17px;
    }
  }

  textarea {
    padding: 16px 34px 16px 16px;

    &:disabled {
      background-color: rgba(255, 255, 255, 0.4);
      color: rgba(127, 129, 151, 1);
    }
  }

  $big-size: 36px;
  $small-size: 28px;
  .microphone-container {
    position: absolute;
    height: $big-size;
    width: $big-size;
    right: 16px;
    bottom: 16px;
    @include media-breakpoint-up(md) {
      right: 24px;
      bottom: 24px;
    }
  }
  .microphone-container2 {
    position: relative;
    height: $big-size;
    width: $big-size;
    height: 100%;
    width: 100%;
  }
  .microphone {
    position: absolute;
    height: $small-size;
    width: $small-size;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    transition: 0.3s ease-in;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    svg {
      path {
        transition: 0.3s ease-in;
      }
    }
    $active-color: $white;
    &.active {
      background-color: $orange !important;
      animation: 1s linear infinite pulsating;
      svg {
        path {
          stroke: $active-color !important;
        }
        path:first-child {
          fill: $active-color !important;
        }
      }

      @keyframes pulsating {
        50% {
          height: $big-size;
          width: $big-size;
        }
      }
    }
    $hover-color: $slate;
    &:hover {
      background-color: $slate10;
      svg {
        path {
          stroke: $hover-color;
        }
        path:first-child {
          fill: $hover-color;
        }
      }
    }
  }
}

</style>

<template>
  <b-button-group
    :id="'copy-and-print-controls-' + id"
    class="d-flex align-items-center h-56px"
  >
    <b-btn :id="'print-summary-btn-' + id"
           :to="`/print-summary/${callId}`"
           variant="secondary"
           class="link-btn b-l-12px"
           target="_blank"
    >
      <img src="/img/icons/print-icon.svg"/>
    </b-btn>
    <b-tooltip
      :target="'print-summary-btn-' + id"
      :title="staticText.printCallSummaryBtn"
      :delay="{ show: 100, hide: 400 }"
      triggers="hover"
    ></b-tooltip>

    <!-- edit summary button -->
    <router-link :id="'edit-summary-btn-' + id"
                 :to="`/conversation/${callId}?editConversation=true`"
                 class="btn btn-secondary link-btn"
                 :replace="isApplicationInIFrame"
    >
      <img src="/img/icons/edit-icon.svg"/>
    </router-link>
    <b-tooltip
      :target="'edit-summary-btn-' + id"
      :title="staticText.editBtnLabel"
      :delay="{ show: 100, hide: 400 }"
      triggers="hover"
    ></b-tooltip>

    <b-btn :id="'action-btn-' + id"
           v-if="actionButtonConfig && actionButtonConfig.length > 0"
           :href="actionButtonConfig[0].url"
           variant="secondary"
           class="link-btn"
           target="_blank"
    >
      <img src="/img/icons/arrows-send-icon.svg"/>
    </b-btn>
    <b-tooltip
      v-if="actionButtonConfig && actionButtonConfig.length > 0"
      :target="'action-btn-' + id"
      :title="actionButtonConfig[0].url"
      :delay="{ show: 100, hide: 400 }"
      triggers="hover"
    ></b-tooltip>

    <!-- delete call button -->
    <b-btn class="p-0 h-100 b-r-12px">
      <bao-delete-button
        :id="id"
        extraClass="bg-transparent mx-auto d-flex align-items-center b-r-8px"
        tooltip-placement="top"
        @delete="$emit('deleteCall')"
      >
      </bao-delete-button>
    </b-btn>
  </b-button-group>
</template>

<script>
import BaoDeleteButton from "@/apps/base/BaoDeleteButton"
import axios from "axios"

export default {
  name: "CallSummaryControllers",
  components: {
    BaoDeleteButton
  },
  data () {
    return {
      axios,
      staticTextDefault: {
        printCallSummaryBtn: "Print",
        editBtnLabel: "Edit",
        delete: "Delete"
      }
    }
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    callId: {
      type: [Number, String],
      required: true
    },
    summary: {
      type: Object,
      required: true
    },
    actionButtonConfig: {
      type: Array,
      required: false
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  }
}
</script>

<style scoped lang="scss">

.link-btn {
  opacity: 1;
}
.h-56px {
  height: 56px;
}
</style>

<template>
  <div>
    <div class="advanced-search flex-shrink-0">
      <button
        class="search-button"
        @click="toggleShowAllFilters"
      >
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'mr-2'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 3h12m0 0a2 2 0 104 0 2 2 0 00-4 0zM5 9h12M5 9a2 2 0 11-4 0 2 2 0 014 0zm-4 6h12m0 0a2 2 0 104 0 2 2 0 00-4 0z" stroke="#555775" stroke-width="2" stroke-linecap="round"/></svg>
        {{ staticText.advancedFilterLabel }}
      </button>
      <transition name="animate-down">
        <div
          v-if="showAllFilters"
          class="filters"
        >
          <div class="filters__active">
            <div
              v-for="(filter, index) in activeFilters"
              :key="filter.key"
              class="active-filter"
            >
              <span>
                {{ filter.label }} {{ filter.labelSuffix ? filter.labelSuffix : "" }}
              </span>
              <component
                :ref="`filter-component-${filter.key}`"
                :is="filter.component"
                :value="filter.value"
                :isOpenFromAdvancedFilters="true"
                :filter-type="filter.key"
                @input="value => handleFiltersChanged(false, filter, index, value)"
                @delete="handleFiltersChanged(false, filter, index)"
              ></component>
            </div>
          </div>
          <div class="filters__actions">
            <button
              :class="['add-new', { show: !!inactiveFilters.length }]"
              @click="showInactiveFilters = !showInactiveFilters"
            >
              <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="showInactiveFilters" v-bind:class="'mr-2'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M6.6 0h4.8c.331 0 .6.336.6.75s-.269.75-.6.75H.6c-.331 0-.6-.336-.6-.75S.269 0 .6 0h6z" fill="#555775"/></svg>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" v-else="" v-bind:class="'mr-2'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M6 1v10m5-5H1" stroke="#555775" stroke-width="2" stroke-linecap="round"/></svg>
              {{ staticText.addNewFilterLabel }}
            </button>
            <button
              :class="['clear-all', { show: !!activeFilters.length }]"
              @click="resetFilters"
            >
              {{ staticText.clearAllLabel }}
            </button>
          </div>
          <transition name="animate-down">
            <div
              v-if="showInactiveFilters && !!inactiveFilters.length"
              class="filters__inactive"
            >
              <button
                v-for="(filter, index) in inactiveFilters"
                :key="filter.key"
                class="inactive-filter"
                @click="activateFilter(index)"
              >
                {{ filter.label }}
              </button>
            </div>
          </transition>
          <div
            v-if="!!activeFilters.length"
            class="w-100 d-flex px-3 pb-3"
          >
            <button
              class="btn btn-primary m-auto apply-button"
              @click="validateFiltersAndFilterCalls(false, null)"
            >
              {{ staticText.applyLabel }}
            </button>
          </div>
        </div>
      </transition>
    </div>
    <div :class="['d-flex', 'flex-wrap', {'mt-2': activatedFilters.length>0}]">
      <div
        v-for="(filter, index) in activatedFilters"
        :key="filter.key"
        class="single-filter"
      >
        <button
          :id="`highlighted-filter-${index}`"
          class="highlighted-filter"
          @click="toggleIndividualFilter(index)"
        >
          <span>{{ filter.label }}</span>
          <div>
            <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" @click="handleFiltersChanged(true, filter, index)" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="a" fill="#fff"><path d="M7 .705L6.295 0 3.5 2.795.705 0 0 .705 2.795 3.5 0 6.295.705 7 3.5 4.205 6.295 7 7 6.295 4.205 3.5 7 .705z"/></mask><path d="M7 .705L6.295 0 3.5 2.795.705 0 0 .705 2.795 3.5 0 6.295.705 7 3.5 4.205 6.295 7 7 6.295 4.205 3.5 7 .705z" fill="#000" fill-opacity=".5"/><path d="M7 .705l.707.707.707-.707-.707-.707L7 .705zM6.295 0l.707-.707-.707-.707-.707.707.707.707zM3.5 2.795l-.707.707.707.707.707-.707-.707-.707zM.705 0l.707-.707-.707-.707-.707.707L.705 0zM0 .705l-.707-.707-.707.707.707.707L0 .705zM2.795 3.5l.707.707.707-.707-.707-.707-.707.707zM0 6.295l-.707-.707-.707.707.707.707L0 6.295zM.705 7l-.707.707.707.707.707-.707L.705 7zM3.5 4.205l.707-.707L3.5 2.79l-.707.707.707.707zM6.295 7l-.707.707.707.707.707-.707L6.295 7zM7 6.295l.707.707.707-.707-.707-.707L7 6.295zM4.205 3.5l-.707-.707-.707.707.707.707.707-.707zM7.707-.002l-.705-.705L5.588.707l.705.705L7.707-.002zm-2.12-.705L2.794 2.088l1.414 1.414L7.002.707 5.588-.707zm-1.38 2.795L1.412-.707-.002.707l2.795 2.795 1.414-1.414zM-.003-.707l-.704.705L.707 1.412l.705-.705L-.002-.707zm-.704 2.12l2.795 2.794 1.414-1.414L.707-.002-.707 1.412zm2.795 1.38L-.707 5.588.707 7.002l2.795-2.795-1.414-1.414zm-2.795 4.21l.705.704 1.414-1.414-.705-.705-1.414 1.414zm2.12.704l2.794-2.795-1.414-1.414-2.795 2.795 1.414 1.414zm1.38-2.795l2.795 2.795 1.414-1.414-2.795-2.795-1.414 1.414zm4.21 2.795l.704-.705-1.414-1.414-.705.705 1.414 1.414zm.704-2.12L4.912 2.794 3.498 4.207l2.795 2.795 1.414-1.414zm-2.795-1.38l2.795-2.795L6.293-.002 3.498 2.793l1.414 1.414z" fill="#2A2D52" fill-opacity=".01" mask="url(#a)"/></svg>
          </div>
        </button>
        <transition name="animate-down">
          <div
            v-if="index === currentVisibleIndividualFilterIndex"
            class="filters"
            :style="getComputedLeft"
          >
            <div class="filters__active">
              <div
                class="active-filter"
              >
                <span>
                  {{ filter.label }} {{ filter.labelSuffix ? filter.labelSuffix : "" }}
                </span>
                <component
                  :ref="`filter-component-${filter.key}-${filter.key}`"
                  :is="filter.component"
                  :value="filter.value"
                  :isOpenFromAdvancedFilters="true"
                  :filter-type="filter.key"
                  @input="value => handleFiltersChanged(true, filter, index, value)"
                  @delete="handleFiltersChanged(true, filter, index)"
                ></component>
              </div>
            </div>
            <div
              class="w-100 d-flex p-3"
            >
              <button
                class="btn btn-primary m-auto apply-button"
                @click="validateFiltersAndFilterCalls(true, index)"
              >
                {{ staticText.applyLabel }}
              </button>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside"
import TimeframeFilter from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/TimeframeFilter"
import DurationFilter from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/Filter/DurationFilter"
import CounterpartFilter
  from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/Filter/CounterpartFilter"
import PlaybookFilter from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/PlaybookSelector"
import PlaybookItemAnswerFilters
  from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/Filter/PlaybookItemAnswerFilters"
import AvailableFilter from "./AvailableFilter.vue"
import UserFilter from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/Filter/UserFilter"
import TranscriptSearchFilter
  from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/Filter/TranscriptSearchFilter"
import { mapGetters } from "vuex"
import {
  FILTER_KEY_TEXT_MATCH,
  FILTER_KEY_COUNTERPART,
  FILTER_KEY_TIMEFRAME,
  FILTER_KEY_USER,
  FILTER_KEY_TRANSCRIPT_SEARCH,
  FILTER_KEY_CALLS_ENDED_WITH_QUICK_END,
  FILTER_KEY_PLAYBOOK
} from "@/apps/dashboard/chartUtils"
import {
  COUNTERPART_AVAILABLE_FILTER_LABEL,
  AUDIO_AVAILABLE_FILTER_LABEL,
  VIDEO_AVAILABLE_FILTER_LABEL,
  PLAYBOOK_ITEM_ANSWER_FILTER_LABEL,
  CALL_DURATION_FILTER_LABEL,
  ARRAY_DATA_TYPE_LABEL,
  transformFilters
} from "@/apps/call_history/CallListComponents/filtersUtils.js"
import PercentageFilter
  from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/Filter/PercentageFilter"

export default {
  name: "AdvancedFilters",
  directives: {
    clickOutside: vClickOutside.directive
  },
  data () {
    return {
      activeFilters: [],
      activatedFilters: [],
      inactiveFilters: [],
      staticTextDefault: {
        advancedFilterLabel: "Select Filters",
        addNewFilterLabel: "New Filter",
        clearAllLabel: "Clear all",
        counterpartAvailableLabel: "Counterpart available",
        audioAvailableLabel: "Audio available",
        videoAvailableLabel: "Video available",
        counterpartLabel: "Counterpart",
        dateRangeLabel: "Date Range",
        userLabel: "User",
        callDurationLabel: "Call Duration",
        timeframeLabel: "(in seconds)",
        playbookLabel: "Playbook",
        playbookItemAnswerLabel: "Playbook + Item + Answer",
        textMatchLabel: "Text-Match",
        applyLabel: "Apply",
        errorLabel: "Error",
        browseTranscript: "Browse Transcript",
        filterErrorMessage: "Please pay attention to the hints in the selection field.",
        callsEndedWithQuickEnd: "Calls Ended with Quick Call End"
      },
      showAllFilters: false,
      showInactiveFilters: true,
      currentVisibleIndividualFilterIndex: null
    }
  },
  mounted () {
    this.inactiveFilters = [...this.defaultFilters]
  },
  computed: {
    ...mapGetters({
      currentUserIsAdminOrSuperuser: "auth/isAdminOrSuperUser",
      showTextMatch: "auth/showTextMatch",
      canUseBaoAudio: "auth/canUseBaoAudio",
      canFilterOnUserBasis: "auth/canFilterOnUserBasis",
      canUseVideoIntegration: "auth/canUseVideoIntegration"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](
        this.$options.name,
        this.staticTextDefault
      )
    },
    defaultFilters () {
      const baseFilters = [
        {
          key: "counterpartAvailableFilter",
          component: AvailableFilter,
          label: this.staticText.counterpartAvailableLabel,
          value: null,
          defaultValue: null,
          query_label: COUNTERPART_AVAILABLE_FILTER_LABEL
        },
        {
          key: "audioAvailableFilter",
          component: AvailableFilter,
          label: this.staticText.audioAvailableLabel,
          value: null,
          defaultValue: null,
          query_label: AUDIO_AVAILABLE_FILTER_LABEL
        },
        {
          key: "counterpartFilter",
          component: CounterpartFilter,
          label: this.staticText.counterpartLabel,
          value: [],
          defaultValue: [],
          query_label: FILTER_KEY_COUNTERPART
        },
        {
          key: "dateRangeFilter",
          component: TimeframeFilter,
          label: this.staticText.dateRangeLabel,
          value: {},
          defaultValue: {},
          query_label: FILTER_KEY_TIMEFRAME
        },
        {
          key: "playbookFilter",
          component: PlaybookFilter,
          label: this.staticText.playbookLabel,
          value: [],
          defaultValue: [],
          query_label: FILTER_KEY_PLAYBOOK
        },
        {
          key: "playbookItemAnswerFilter",
          component: PlaybookItemAnswerFilters,
          label: this.staticText.playbookItemAnswerLabel,
          value: {},
          defaultValue: {},
          query_label: PLAYBOOK_ITEM_ANSWER_FILTER_LABEL
        },
        {
          key: "callDurationFilter",
          component: DurationFilter,
          label: this.staticText.callDurationLabel,
          labelSuffix: this.staticText.timeframeLabel,
          value: {},
          defaultValue: {},
          query_label: CALL_DURATION_FILTER_LABEL
        },
        {
          key: "endedWithQuickCallEndItem",
          component: AvailableFilter,
          label: this.staticText.callsEndedWithQuickEnd,
          value: null,
          defaultValue: null,
          query_label: FILTER_KEY_CALLS_ENDED_WITH_QUICK_END
        }
      ]
      if (this.currentUserIsAdminOrSuperuser && this.canFilterOnUserBasis) {
        baseFilters.push(
          {
            key: "userFilter",
            component: UserFilter,
            label: this.staticText.userLabel,
            value: {},
            defaultValue: {},
            query_label: FILTER_KEY_USER
          }
        )
      }
      if (this.showTextMatch) {
        baseFilters.push(
          {
            key: "textMatchFilter",
            component: PercentageFilter,
            label: this.staticText.textMatchLabel,
            value: {},
            defaultValue: {},
            query_label: FILTER_KEY_TEXT_MATCH
          }
        )
      }
      if (this.canUseBaoAudio) {
        baseFilters.push(
          {
            key: "transcriptSearchFilter",
            component: TranscriptSearchFilter,
            label: this.staticText.browseTranscript,
            value: null,
            defaultValue: null,
            query_label: FILTER_KEY_TRANSCRIPT_SEARCH
          }
        )
      }
      if (this.canUseVideoIntegration) {
        baseFilters.push(
          {
            key: "videoAvailableFilter",
            component: AvailableFilter,
            label: this.staticText.videoAvailableLabel,
            value: null,
            defaultValue: null,
            query_label: VIDEO_AVAILABLE_FILTER_LABEL
          }
        )
      }
      return baseFilters
    },
    getComputedLeft () {
      if (this.currentVisibleIndividualFilterIndex !== null) {
        const parentElement = document.getElementById(`highlighted-filter-${this.currentVisibleIndividualFilterIndex}`)
        // This is the width of the filter container as defined in the css below
        const elementWidth = 520
        const parentDimensions = parentElement.getBoundingClientRect()
        const parentDistanceFromRight = window.innerWidth - parentDimensions.right - 28 // The 28 represents the padding and extra margin
        const totalParentDistanceToRight = parentDistanceFromRight + parentDimensions.width
        const elementLeft = totalParentDistanceToRight > elementWidth ? 0 : totalParentDistanceToRight - elementWidth
        return {
          left: `${elementLeft}px`
        }
      }
      return {}
    }
  },
  methods: {
    activateFilter (index) {
      const activeFilter = this.inactiveFilters.splice(index, 1)[0]
      // This is to ensure the previous value selected incase that's the case, is reverted
      this.activeFilters.push({ ...activeFilter, value: activeFilter.defaultValue })
    },
    toggleIndividualFilter (index) {
      this.currentVisibleIndividualFilterIndex = this.currentVisibleIndividualFilterIndex !== index ? index : null

      if (this.showAllFilters === false) {
        this.updateFilterValue(index, "activatedFilter")
      } else {
        this.updateActiveFilters()
        this.showAllFilters = false
      }
    },
    toggleShowAllFilters () {
      this.showAllFilters = !this.showAllFilters
      if (this.currentVisibleIndividualFilterIndex === null) {
        if (this.showAllFilters === false) {
          this.updateActiveFilters()
        }
      } else {
        this.updateFilterValue(this.currentVisibleIndividualFilterIndex, "activatedFilter")
        this.currentVisibleIndividualFilterIndex = null
      }
    },
    updateActiveFilters () {
      this.activeFilters = this.activatedFilters.map(filter => ({ ...filter }))
      this.inactiveFilters = this.defaultFilters.filter(filter => this.filterIsInactive(filter))
    },
    updateFilterValue (index, filterToUpdate) {
      const filtersToUpdate = filterToUpdate === "activatedFilter" ? this.activatedFilters : this.activeFilters
      const filtersToCopy = filterToUpdate === "activatedFilter" ? this.activeFilters : this.activatedFilters
      const filterValue = filtersToCopy[index].value
      const filterValueType = typeof filterValue
      filtersToUpdate[index].value = Array.isArray(filterValue) ? [...filterValue] : filterValueType === "object" && filterValue !== null ? { ...filterValue } : filterValue
    },
    filterIsInactive (filter) {
      const filterIndex = this.activeFilters.findIndex(activeFilter => activeFilter.key === filter.key)
      return filterIndex === -1
    },
    resetFilters () {
      this.activeFilters = []
      this.activatedFilters = []
      this.currentVisibleIndividualFilterIndex = null
      this.inactiveFilters = [...this.defaultFilters]
      this.clearAllFilters()
    },
    handleFiltersChanged (isIndividualFilter, filter, index, value = null) {
      if (value === null) {
        const filter = this.activeFilters.splice(index, 1)
        this.currentVisibleIndividualFilterIndex = null
        this.inactiveFilters.unshift(filter[0])
        if (isIndividualFilter) this.filterCalls()
        if (this.activeFilters.length === 0) this.clearAllFilters()
      } else {
        filter.value = value
      }
    },
    async validateFiltersAndFilterCalls (isIndividualFilter, index) {
      this.hideGlobalToast()
      if (await this.validateAllFilters(isIndividualFilter, index)) {
        this.filterCalls()
      } else {
        this.showErrorToast(this.staticText.filterErrorMessage)
      }
    },
    async filterCalls () {
      const transformedFilters = transformFilters({ dataType: ARRAY_DATA_TYPE_LABEL, activeFilters: this.activeFilters })
      this.$emit("filter-calls", transformedFilters)
      this.resetAllFiltersVisibility()
      this.activatedFilters = this.activeFilters.map(filter => ({ ...filter }))
    },
    clearAllFilters () {
      this.$emit("filter-calls", {})
      this.activatedFilters = []
    },
    resetAllFiltersVisibility () {
      this.showAllFilters = false
      this.currentVisibleIndividualFilterIndex = null
    },
    async validateAllFilters (isIndividualFilter, index) {
      let errorCount = 0
      if (isIndividualFilter) {
        // This is to validate individual filter first, incase it's an individual filter opened and deal with
        // it individually if there's any error before validating the other active filters
        const refLabel = `filter-component-${this.activatedFilters[index].key}-${this.activatedFilters[index].key}`
        const validatedFilter = this.$refs[refLabel][0].validateFilter()
        if (!validatedFilter) return false
        else {
          this.updateFilterValue(index, "activeFilter")
          return true
        }
      } else {
        for await (const filter of this.activeFilters) {
          const refLabel = `filter-component-${filter.key}`
          const validatedFilter = this.$refs[refLabel][0].validateFilter()
          if (!validatedFilter) errorCount++
        }
      }
      return errorCount <= 0
    },
    showErrorToast (description) {
      const errorMessage = {
        title: this.staticText.errorLabel,
        description
      }
      this.showGlobalToast({
        status: "error",
        message: errorMessage
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.advanced-search {
  position: relative;

  .search-button {
    display: flex;
    align-items: center;
    padding: 16px;
    background: $white40;
    border-radius: 12px;
    width: 200px;
    border: none;
    outline: none;
  }
}

.single-filter {
  position: relative;
  margin: 0 8px 8px 0;

  .highlighted-filter {
    background: $slate06;
    border-radius: 12px;
    padding: 6px 10px;
    outline: none;
    border: none;
    display: flex;
    align-items: center;

    div {
      width: 20px;
      height: 20px;
      margin-left: 11.8px;
      border-radius: 50%;
      background-color: $slate60;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s ease-in;

      &:hover {
        background-color: $slate80;
      }

      svg {
        width: 10px;
        height: 10px;

        path {
          fill: #E4DCD7;
          fill-opacity: 1;
        }
      }
    }
  }
}

.filters {
  width: 520px;
  max-width: 100vw;
  background: $white;
  box-shadow: 6px 8px 20px 5px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  position: absolute;
  z-index: 999;
  top: calc(100% + 10px);

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;

    button {
      border: none;
      outline: none;
      padding: 0;
      background-color: transparent;
      display: flex;
      align-items: center;
      transition: opacity 0.3s ease-in;
      opacity: 0;

      &.show {
        opacity: 1;
      }
    }
  }

  &__active {
    .active-filter {
      padding: 16px;
      border-bottom: 1px solid $slate20;
      display: flex;
      align-items: center;

      span {
        margin-right: 8px;
      }
    }
  }

  &__inactive {
    margin: 0 8px 8px 16px;

    .inactive-filter {
      outline: none;
      padding: 8px 10px;
      border: 1px solid $slate20;
      border-radius: 12px;
      background-color: transparent;
      margin: 0 8px 8px 0;
    }
  }
}

.apply-button {
  padding: 10px 16px !important;
  border-radius: 16px;
}
</style>

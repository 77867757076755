<template>
  <div class="mx-3">
    <h4 class="font-weight-bold">
      {{ staticText.selectCounterpartMessage }}
    </h4>

    <p class="small">
      {{ staticText.captionInformation }}
    </p>

    <div v-for="(counterpartType, typeIndex) in counterparts"
         :key="typeIndex"
         class="my-2"
    >
      <p>
        <span :id="'counterpart-type-202103081137-'+typeIndex"
              class="font-weight-bold"
        >
          {{ counterpartType.type }}
        </span>

        <span :id="'save-crm-fields-label-202103081137-'+typeIndex"
              v-if="typeIndex===0"
              class="float-right"
        >
          {{ staticText.saveCRMFields }}
        </span>
      </p>

      <b-list-group class="counterparts-block">
        <b-list-group-item
          v-for="(counterpart, index) in counterpartType.counterparts"
          :key="typeIndex+index"
        >
          <counterpart-display-cell
            :id="'counterpart-display-202103081139-'+typeIndex+index"
            :name="counterpart.name"
            :type="counterpart.type"
            :crm_service="counterpart.crm_service"
          >
            <template slot="end">
              <b-form-checkbox
                :id="'counterpart-checkbox-202103081139-'+typeIndex+index"
                v-model="counterpart.save_crm_fields"
              >
              </b-form-checkbox>
            </template>
          </counterpart-display-cell>
        </b-list-group-item>
      </b-list-group>
    </div>
    <div class="mt-4 text-center d-flex justify-content-between">
      <b-btn id="cancel-btn-202103051221"
             variant="secondary"
             class="font-size-14 px-4"
             @click="cancel"
      >
        <i id="cancel-icon-202103051221"
           class="fas fa-reply"
        ></i>
        {{ staticText.cancel }}
      </b-btn>

      <b-btn id="save-to-crm-btn-202103051221"
             variant="primary"
             class="font-size-14 px-4"
             @click="save"
      >
        <img id="save-to-crm-icon-202103051221"
             src="/img/icons/light-save-icon.svg"
             class="text-white"
        />
        {{ staticText.saveToCRMLabel }}
      </b-btn>
    </div>
  </div>
</template>
<script>

import CounterpartDisplayCell from "@/apps/call/CounterpartDisplayCell"

export default {
  name: "callSummaryCounterpartSelection",
  components: {
    CounterpartDisplayCell
  },
  props: {
    value: { // grouped counterparts
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
      counterparts: [],
      staticTextDefault: {
        saveCRMFields: "Save CRM fields",
        saveToCRMLabel: "Save to CRM",
        selectCounterpartMessage: "Select counterparts to save CRM fields",
        captionInformation: "Call Notes will be saved to all counterparts.",
        cancel: "Cancel"
      }
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  watch: {
    counterparts (newVal) {
      this.$emit("input", newVal)
    }
  },
  mounted () {
    this.counterparts = this.value
  },
  methods: {
    cancel () {
      this.$emit("cancel")
    },
    save () {
      this.$emit("save")
    }
  }
}
</script>

<style scoped lang="scss">

</style>

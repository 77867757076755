<template>
  <div class="w-100">
    <!-- adcs -->
    <div
      v-if="callFlowItem.isAdditionalItem"
    >
      <div
        :style="{'margin-left': `${16 * level}px`}"
        class="mb-3 callItem-title align-items-center"
      >
        <i :class="getADCClassForIcon(callFlowItem)"></i>
        <div class="text">{{ getItemName }}</div>
      </div>
      <div
        v-for="playbookItem in callFlowItem.children"
        :id="`${callFlowItem.id}-${playbookItem.id}-ADCChild`"
        :key="`${callFlowItem.id}-${playbookItem.id}-ADCChild`"
      >
        <call-flow-call-item
          :item-position="playbookItem.playbookIndex+1"
          :call-flow-item="playbookItem"
          :level="level + 1"
          :isCallSavedToCrm="isCallSavedToCrm"
          :can-use-ai-answers="canUseAiAnswers"
          v-on="$listeners"
        />
      </div>
    </div>
    <div
      v-else
      class="row mb-2"
    >
      <div class="col-12 col-md-4 mb-2 mb-md-0">
        <div
          class="callItem-title align-items-center align-items-md-start"
          :style="{'margin-left': `${16 * level}px`}"
        >
          <span class="d-flex w-100 w-md-auto">
            <span>{{ `${itemPosition}.` }}</span>
            <div class="text ml-2 d-flex flex-md-column align-items-center align-items-md-start">
              {{ getItemName }}

              <component
                v-if="isItemType(callFlowItem, 'crmlink') && callFlowItem.linked_field"
                :is="getCrmLogo(getCrmService)"
                class="ml-1 ml-md-0 mt-md-1"
              />
            </div>
          </span>
          <CallFlowItemsAIAnswerButton
            v-if="canHaveAIAnswers(callFlowItem) && canUseAiAnswers"
            class="ml-2"
            :revert="!!callFlowItem.hasAIAnswers"
            :loading="!!callFlowItem.loading"
            :loading-failed="!!callFlowItem.noAnswersFromAI"
            :show-label="false"
            @toggle="$emit('ai-answer-btn-toggle', callFlowItem)"
          />
        </div>
      </div>
      <div class="col-12 col-md-8">
        <div
          v-if="isItemWithAnswers(callFlowItem.item_type)"
        >
          <inner-label-dropdown
            class="mb-2"
            :value="getAnswersItemValue(callFlowItem)"
            :label="staticText.answerLabel"
            :placeholder="staticText.selectAnswerLabel"
            :dropdownItems="getTransformedAnswerChoices(callFlowItem)"
            :disabled="isCallSavedToCrm"
            :is-multi-select="!isItemSingleSelect(callFlowItem.item_type)"
            :close-on-select="isItemSingleSelect(callFlowItem.item_type)"
            :clear-on-select="isItemSingleSelect(callFlowItem.item_type)"
            @input="(selectedAnswers) => handleAnswersChanged(selectedAnswers, !isItemSingleSelect(callFlowItem.item_type))"
          />
        </div>
        <div
          v-if="isItemType(callFlowItem, 'crmlink') && callFlowItem.linked_field"
        >
          <call-flow-crm-item
            :call-flow-item="callFlowItem"
            :isCallSavedToCrm="isCallSavedToCrm"
            @item-changed="handleCrmItemChanged"
          />
        </div>
        <div
          v-if="showNotesField(callFlowItem)"
        >
          <inner-label-notes
            :value="getNotesValue(callFlowItem)"
            :label="staticText.notesLabel"
            :placeholder="staticText.enterNotesLabel"
            :disabled="isCallSavedToCrm"
            @input="handleNotesChanged"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InnerLabelDropdown from "@/apps/base/InnerLabelDropdown.vue"
import InnerLabelNotes from "@/apps/base/InnerLabelNotes.vue"
import CallItemMixin from "@/apps/call/CallItemMixin"
import CrmLogoMixin from "./CrmLogoMixin"
import CallFlowCrmItem from "./CallFlowCrmItem.vue"
import { mapActions, mapGetters } from "vuex"
import CallFlowItemsAIAnswerButton from "@/apps/call_history/CallSummaryComponents/CallFlowItemsAIAnswerButton"

export default {
  name: "CallFlowCallItem",
  mixins: [CallItemMixin, CrmLogoMixin],
  components: {
    InnerLabelDropdown,
    InnerLabelNotes,
    CallFlowCrmItem,
    CallFlowItemsAIAnswerButton
  },
  data () {
    return {
      staticTextDefault: {
        selectAnswerLabel: "Select Answer",
        enterNotesLabel: "Enter Notes",
        notesLabel: "Notes",
        answerLabel: "Answer"
      }
    }
  },
  props: {
    itemPosition: {
      type: [Number, String],
      required: true
    },
    callFlowItem: {
      type: Object,
      default: () => {
      }
    },
    level: {
      type: Number,
      default: 0,
      required: false
    },
    isCallSavedToCrm: {
      type: Boolean,
      required: false,
      default: false
    },
    canUseAiAnswers: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      callDetails: "callSummaryStore/getCallDetails"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    getItemName () {
      return this.callFlowItem.isAdditionalItem ? this.callFlowItem.selected_choice.name : this.callFlowItem.name
    },
    getCrmService () {
      return this.callFlowItem.linked_field.crm_object_link.service_key
    }
  },
  methods: {
    ...mapActions({
      handleCallItemChanged: "callSummaryStore/handleCallItemChanged"
    }),
    getSingleSelectItemValue (item) {
      if (item.selectedAnswers) {
        return item.selectedAnswers[0]
      }
      return this.hasAnswers(item) ? item.call_item.answers[0] : null
    },
    getNotesValue (item) {
      return item.call_item && !!item.call_item.note ? item.call_item.note : ""
    },
    getMultiSelectItemValue (item) {
      if (item.selectedAnswers) {
        return item.selectedAnswers
      }
      return this.hasAnswers(item) ? item.call_item.answers : []
    },
    getTransformedAnswerChoices (item) {
      return item.answer_choices.map((answer) => {
        return this.transformAnswer(answer)
      })
    },
    transformAnswer (answer) {
      return { ...answer, question_id: answer.id, text: answer.label }
    },
    getAnswersItemValue (item) {
      return this.isItemSingleSelect(item.item_type) ? this.getSingleSelectItemValue(item) : this.getMultiSelectItemValue(item)
    },
    handleAnswersChanged (selectedAnswers, isMultiSelect) {
      selectedAnswers = !selectedAnswers
        ? []
        : isMultiSelect
          ? selectedAnswers
          : [selectedAnswers]
      this.$set(this.callFlowItem, "selectedAnswers", selectedAnswers)
      this.saveUpdatedCallItem(this.callFlowItem)
    },
    handleNotesChanged (notes) {
      this.$set(this.callFlowItem, "notes", notes)
      this.handleCallItemChanged({ item: this.callFlowItem, callId: this.callDetails.id })
    },
    handleCrmItemChanged (selectedAnswers) {
      this.$set(this.callFlowItem, "selectedAnswers", selectedAnswers)
      this.saveUpdatedCallItem(this.callFlowItem)
    },
    saveUpdatedCallItem (callItem) {
      this.$emit("item-updated", callItem)
    },
    getADCClassForIcon (adc) {
      return adc.selected_choice && adc.selected_choice.type === "default"
        ? "mr-1 fas fa-book fa-sm"
        : "mr-1 fas fa-bolt fa-sm"
    }
  }
}
</script>

<style lang="scss" scoped>

.callItem-title {
  display: flex;
  font-weight: 700;
  line-height: 19.2px;
  color: $slate;

  div.text {
    word-break: break-word;
  }

  :deep(svg) {
    width: fit-content;
    height: 16px;

    path {
      fill: $slate60;
    }
  }
}

/* MD breakpoint*/
@media (min-width: 768px) {
  /* CUSTOM WIDTHS */
  .w-md-auto {
    width: auto !important;
  }
}

</style>

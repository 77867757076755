<template>
  <div v-b-tooltip.hover.bottom
       :title="tooltipTitle"
  >
    <button
      :class="btnClass"
      :disabled="loading"
      @click="$emit('toggle', revert)"
    >
      <span v-if="loading">
        <progress-indicator
          :small="true"
          :show-loading-label="false"
          :class="{'text-white': showLabel}"
        />
      </span>
      <span v-else>
        <img :src="iconSrc" svg-inline/>
      </span>

      <span v-if="showLabel" class="ml-1">
        {{ label }}
      </span>
    </button>
  </div>
</template>

<script>
import ProgressIndicator from "@/apps/base/ProgressIndicator"

export default {
  name: "CallFlowItemsAIAnswerButton",
  components: { ProgressIndicator },
  data () {
    return {
      icons: {
        loadAnswersIcon: "/img/aiAnswerIcons/magic-star-white.svg",
        loadAnswersForItemIcon: "/img/aiAnswerIcons/magic-star-orange.svg",
        revertAnswersIcon: "/img/aiAnswerIcons/revert-icon-dark.svg",
        revertAnswersForItemIcon: "/img/aiAnswerIcons/revert-icon-slate.svg",
        loadAnswersFailedIcon: "/img/aiAnswerIcons/question-icon-white.svg",
        loadAnswersFailedForItemIcon: "/img/aiAnswerIcons/question-icon-orange.svg"
      },
      staticTextDefault: {
        fillInText: "Fill in AI suggestions",
        revertText: "Revert to your input",
        fillInAllTooltip: "AI will automatically fill in your items. You can always go back to your initial input/data.",
        revertAllTooltip: "Click to replace all AI-suggestions with your initial data.",
        noFillItemsText: "AI couldn't pre-fill any answers",
        noFillItemText: "AI couldn't pre-fill this item"
      }
    }
  },
  props: {
    revert: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    loadingFailed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    label () {
      return this.revert
        ? this.staticText.revertText
        : this.staticText.fillInText
    },
    iconSrc () {
      if (this.loadingFailed) {
        return this.showLabel ? this.icons.loadAnswersFailedIcon : this.icons.loadAnswersFailedForItemIcon
      }
      return this.revert
        ? this.showLabel ? this.icons.revertAnswersIcon : this.icons.revertAnswersForItemIcon
        : this.showLabel ? this.icons.loadAnswersIcon : this.icons.loadAnswersForItemIcon
    },
    btnClass () {
      return this.revert
        ? this.showLabel ? "ai-answers-revert-btn" : "ai-answer-btn-no-label"
        : this.showLabel ? "ai-answers-fill-btn" : "ai-answer-btn-no-label"
    },
    tooltipTitle () {
      if (this.loadingFailed) {
        return this.showLabel ? this.staticText.noFillItemsText : this.staticText.noFillItemText
      }
      return this.revert
        ? this.showLabel ? this.staticText.revertAllTooltip : this.staticText.revertText
        : this.showLabel ? this.staticText.fillInAllTooltip : this.staticText.fillInText
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  }
}
</script>

<style scoped lang="scss">

</style>

<template>
  <div class="d-flex">
    <base-alert
      id="readonly-or-not-supported-202211122323"
      v-if="nonEditableField"
      :alert-message="fieldDefinition.readOnly ? staticText.readOnly : staticText.notSupported"
      :show-alert="nonEditableField"
      variant="danger"
    ></base-alert>

    <div class="w-100">
      <b-form-input :id="'text-input-' + itemId + '-' + fieldDefinition.id"
                    v-if="isText(type) || isNumber(type)"
                    v-model="internalValue"
                    :type="type"
                    :disabled="nonEditableField"
                    @input="onChange"
      ></b-form-input>

      <b-form-textarea :id="'textarea-'+ itemId + '-' + fieldDefinition.id"
                       v-else-if="isTextArea(type)"
                       v-model="internalValue"
                       :disabled="nonEditableField"
                       rows="3"
                       max-rows="6"
                       @input="onChange"
      ></b-form-textarea>

      <VueCtkDateTimePicker :id="'date-time-picker-'+ itemId + '-' + fieldDefinition.id"
                            v-else-if="isDateTime(type) || isDate(type) || isTime(type)"
                            v-model="internalValue"
                            :format="datetimeFormatField"
                            :output-format="datetimeFormatField"
                            :formatted="datetimeFormatField"
                            :only-date="onlyDate(type)"
                            :only-time="onlyTime(type)"
                            :disabled="nonEditableField || isDisabled"
                            :label="dateTimeLabel(type)"
                            :no-label="true"
                            :right="true"
                            :class="dateTimePickerClass"
                            @input="onChange"
      ></VueCtkDateTimePicker>

      <bao-vue-multi-select :id="'multiselect-'+ itemId + '-' + fieldDefinition.id"
                            v-else-if="isSelect(type) && fieldDefinition.options"
                            v-model="internalValue"
                            :options="fieldDefinition.options"
                            :multiple="canSelectMultiple(type)"
                            :close-on-select="!canSelectMultiple(type)"
                            :clear-on-select="!canSelectMultiple(type)"
                            :disabled="nonEditableField"
                            :placeholder="staticText.selectOptionPlaceholder"
                            label="label"
                            track-by="value"
                            :show-labels="false"
                            @input="onChange"
      ></bao-vue-multi-select>

      <div
        v-else-if="isLookup(type)"
        :class="{'inner-label-dropdown-202403211841 w-100' : isOpenFromCallWrapUp }"
      >
        <CrmLookupFieldInput
          :id="'lookup-'+ itemId + '-' + fieldDefinition.id"
          v-model="internalValue"
          :linked-field="linkedField"
          :field-definition="fieldDefinition"
          :disabled="isDisabled"
          @input="onChange"
        />
      </div>

      <b-form-radio-group :id="'radio-' + itemId + '-' + fieldDefinition.id"
                          v-else-if="isRadio(type)"
                          v-model="internalValue"
                          value-field="value"
                          text-field="label"
                          stacked
                          :options="fieldDefinition.options"
                          :disabled="nonEditableField"
                          @change="onChange"
      >
      </b-form-radio-group>

      <b-form-checkbox :id="'checkbox-' + itemId + '-' + fieldDefinition.id"
                       v-else-if="isSingleSelectBox(type)"
                       v-model="internalValue"
                       :disabled="nonEditableField || isDisabled"
                       name="checkbox"
                       value="true"
                       unchecked-value="false"
                       :class="checkBoxClass"
                       button-variant="danger"
                       @change="onChange"
      ></b-form-checkbox>

      <b-form-tags :id="'tags-' + itemId + '-' + fieldDefinition.id"
                   v-else-if="isTag(type)"
                   v-model="internalValue"
                   @input="onChange"
      ></b-form-tags>

      <base-form-group-input :id="'form-group-input-' + itemId + '-' + fieldDefinition.id"
                             v-else-if="isFormGroup(type)"
                             v-model="internalValue"
                             :input-keys="fieldDefinition.formKeys"
                             @input="onChange"
      ></base-form-group-input>

      <div :id="'non-editable-' + itemId + '-' + fieldDefinition.id"
           v-else-if="!fieldTypeRecognised(type)"
           class="non-edit-field"
      >
        {{ value }}
      </div>
    </div>
  </div>
</template>

<script>
import { typeServices } from "@/apps/call"
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css"
import BaseFormGroupInput from "@/apps/base/BaseFormGroupInput"
import BaseAlert from "@/apps/base/BaseAlert"
import CrmLookupFieldInput from "@/apps/call/CallViewItem_components/CrmLookupFieldInput"
import BaoVueMultiSelect from "@/apps/base/BaoVueMultiSelect"

export default {
  name: "TypedInputField",
  components: {
    BaoVueMultiSelect,
    BaseAlert,
    VueCtkDateTimePicker: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "VueCtkDateTimePicker" */ "vue-ctk-date-time-picker"),
    BaseFormGroupInput,
    CrmLookupFieldInput
  },
  props: {
    fieldDefinition: {
      type: Object,
      required: true
    },
    linkedField: {
      type: Object,
      required: true
    },
    value: {
      type: [String, Number, Boolean, Array, Object, Date],
      required: false
    },
    valueObject: { // contains information about value
      type: Object,
      required: false
    },
    itemId: {
      type: [String, Number],
      default: "",
      required: false
    },
    datetimeFormatString: {
      type: String,
      default: ""
    },
    isOpenFromCallWrapUp: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value (newValue) {
      this.internalValue = newValue
    }
  },
  data () {
    return {
      internalValue: this.value,
      staticTextDefault: {
        readOnly: "Read-only field - value cannot be changed",
        notSupported: "This field type is not supported",
        dateOnlyLabel: "Select date",
        timeOnlyLabel: "Select time",
        dateAndTimeLabel: "Select date & time",
        selectOptionPlaceholder: "Select option"
      }
    }
  },
  computed: {
    checkBoxClass () {
      return [
        "mr-3",
        { "ai-answer-checkbox": !!this.valueObject && !!this.valueObject.isAIDetected },
        { "small-size-checkbox": this.isOpenFromCallWrapUp }
      ]
    },
    dateTimePickerClass () {
      return [
        { "ai-answer-datetime": !!this.valueObject && !!this.valueObject.isAIDetected },
        { "inner-label-date": this.isOpenFromCallWrapUp }
      ]
    },
    type () {
      return this.fieldDefinition.fieldType
    },
    datetimeFormatField () {
      if (this.datetimeFormatString.length > 0) {
        return this.datetimeFormatString
      }
      return this.datetimeFormat(this.type)
    },
    nonEditableField () {
      return this.fieldDefinition.readOnly || !this.fieldDefinition.supported
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  methods: {
    ...typeServices,
    onChange () {
      this.$emit("input", this.internalValue)
    },
    dateTimeLabel (fieldType) {
      if (this.onlyDate(fieldType)) return this.staticText.dateOnlyLabel
      if (this.onlyTime(fieldType)) return this.staticText.timeOnlyLabel
      return this.staticText.dateAndTimeLabel
    }
  }
}
</script>

<style scoped lang="scss">
.non-edit-field {
  display: block;
  background-color: #e9ecef;
  color: #495057;
  border-radius: 0.25rem;
  font-size: 1rem;
  padding: 0.375rem 0.75rem;
  min-height: calc(1.5em + 0.75rem + 2px);
}

.inner-label-date {
  :deep(.field) {
    &.is-focused {
      margin: 0;
    }

    .field-input {
      padding: 32px 20px 21px 16px !important;

      &:disabled {
        background-color: rgba(255, 255, 255, 0.4) !important;
        color: rgba(127, 129, 151, 1) !important;
      }
    }
  }
}

@mixin checkbox-base {
  margin-right: 8px !important;

  :deep(.custom-control-label) {
    &::before,
    &::after {
      height: 18px !important;
      width: 18px !important;
      top: 3px !important;
    }
  }
}

.small-size-checkbox {
  @include checkbox-base;
}

.ai-answer-checkbox {
  @include checkbox-base;

  :deep(.custom-control-label) {
    &::before,
    &::after {
      background-color: $sand !important;
      border: 1px solid $orange40-secondary !important;
    }
  }
}

.ai-answer-datetime {
  :deep(.field-input) {
    background-color: $sand !important;
    border: 1px solid $orange40-secondary !important;
  }

}

</style>

<template>
  <div
    class="save-successful-screen"
  >
    <div class="d-flex flex-column align-items-center">
      <div class="animation-container">
        <div class="pulsating-circle"></div>
        <div class="faded-logo-container"></div>
        <div class="logo-container"></div>
        <component
          v-if="counterpartsAvailable"
          :is="crmLogo"
          class="crm-logo"
        />
        <div class="checkmark draw"></div>
      </div>
      <p>{{ label }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "SaveToCrmSuccessScreen",
  props: {
    label: {
      type: String
    },
    crmLogo: {
      required: false
    },
    counterpartsAvailable: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>

.save-successful-screen {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(233, 247, 230, 1);
  z-index: 9999;

  $container-size: 120px;
  $additional-size: 40px;
  $large-container: calc($container-size + $additional-size);

  .animation-container {
    position: relative;
    height: $large-container;
    width: $large-container;
  }

  .logo-container,
  .faded-logo-container,
  .pulsating-circle,
  .crm-logo {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  .logo-container {
    background-color: rgba(31, 170, 9, 1);
    animation-duration: 1600ms;
    animation-delay: 200ms;
    animation-name: glow2;
    height: 0;
    width: 0;

    @keyframes glow2 {

      25% {
        height: $container-size;
        width: $container-size;
      }

      95% {
        height: $container-size;
        width: $container-size;
      }
    }
  }

  .faded-logo-container {
    background-color: #BFE8BA;
    animation-duration: 500ms;
    animation-name: glow;
    height: $container-size;
    width: $container-size;

    @keyframes glow {
      0% {
        height: 0;
        width: 0;
      }

      80% {
        height: $container-size;
        width: $container-size;
      }

      100% {
        height: $container-size;
        width: $container-size;
      }
    }
  }

  .pulsating-circle {
    border: 10px solid #BFE8BA;
    background-color: transparent;
    opacity: 0;
    animation-delay: 1100ms;
    animation-duration: 400ms;
    animation-name: pulsating;
    animation-timing-function: linear;
    height: $container-size;
    width: $container-size;
    box-shadow: inset 10px 10px 10px 10px #DDF1DA;

    @keyframes pulsating {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 1;
        height: $large-container;
        width: $large-container;
      }
      100% {
        opacity: 1;
      }
    }
  }

  .logo-container,
  .faded-logo-container,
  .pulsating-circle {
    border-radius: 50%;
    animation-timing-function: ease-in;
  }

  .crm-logo {
    animation-delay: 500ms;
    animation-duration: 700ms;
    animation-timing-function: linear;
    animation-name: fade-in-out;
    height: 50px;
    width: 50px;
    opacity: 0;

    @keyframes fade-in-out {
      5% {
        opacity: 1;
      }

      95% {
        opacity: 1;
      }
    }
  }

  .checkmark {
    $check-height: calc($container-size/3);
    $check-width: calc($check-height/2);
    $check-thickness: 5px;
    $check-color: #fff;

    &.draw:after {
      animation-delay: 1200ms;
      animation-duration: 300ms;
      animation-timing-function: linear;
      animation-name: checkmark;
      opacity: 0;
      transform: scaleX(-1) rotate(135deg);
    }

    &:after {
      // opacity: 1;
      height: $check-height;
      width: $check-width;
      transform-origin: left top;
      border-right: $check-thickness solid $check-color;
      border-top: $check-thickness solid $check-color;
      content: '';
      top: calc($large-container/2);
      left: calc($check-height + ($additional-size/2));
      position: absolute;
    }

    @keyframes checkmark {
      0% {
        height: 0;
        width: 0;
        opacity: 1;
      }
      33% {
        height: 0;
        width: $check-width;
        opacity: 1;
      }
      67% {
        height: calc($check-height/2);
        width: $check-width;
        opacity: 1;
      }
      100% {
        height: $check-height;
        width: $check-width;
        opacity: 1;
      }
    }
  }

  p {
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;
    margin: 8px 0 0;
    text-transform: uppercase;
  }
}

</style>

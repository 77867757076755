<template>
  <div class="w-100">
    <div>
      <!-- Save to CRM button-->
      <div v-if="showSaveToCRMBtn">
        <div id="save-to-CRM-btn-20201224256">
          <b-btn
            :disabled="!counterpartsAvailable"
            variant="primary"
            class="px-md-3 crm-save-button"
            @click="checkSimilarCounterpartExists"
          >
            <component
              v-if="counterpartsAvailable"
              :is="getCrmLogo(getCrmService)"
              class="mr-2"
            />
            {{ computedSaveToCrmLabel }}
          </b-btn>
        </div>

        <b-tooltip
          id="no-counterpart-available-tooltip-202012311109"
          v-if="!counterpartsAvailable"
          target="save-to-CRM-btn-20201224256"
          placement="bottom"
        >
          {{ staticText.noCounterpartAvailableTooltip }}
        </b-tooltip>
      </div>

      <!-- Saving indicator -->
      <b-btn
        v-else-if="savingToCRM"
        variant="outline-success"
        class="w-100"
        disabled
      >
        <b-spinner variant="success"
                   small
        ></b-spinner>
        {{ staticText.savingLabel }}
      </b-btn>

      <!-- Already saved indicator -->
      <b-btn
        v-else-if="savedToCRM"
        variant="outline-success"
        class="w-100"
        disabled
      >
        <i class="far fa-check-circle"></i>
        {{ staticText.savedToCRMLabel }}
      </b-btn>

      <!-- Error -->
      <div id="error-modal-202012241507">
        <error-modal
          v-if="CRMErrorsExists"
          :button-text='staticText.errorText'
          :title='staticText.errorSavingCRM'
          :errors="savingToCRMErrors"
        ></error-modal>
        <b-tooltip target="error-modal-202012241507">
          {{ staticText.errorSavingTooltipHoverTitle }}
        </b-tooltip>
      </div>
    </div>

    <!-- Confirmation modal for saving crm data to counterparts -->
    <b-modal
      id="saving-to-crm-confirmation-202103031815"
      v-model="showSavingToCRMConfirmation"
      size="lg"
      hide-footer
    >
      <template #modal-title>
        <img id="counterpart-selection-icon-202103051221"
             src="/img/icons/conversation-icon.svg"
             class="text-white"
        />
        {{ staticText.counterpartSelection }}
      </template>

      <call-summary-counterpart-selection
        v-model="counterpartsGroupedByType"
        @cancel="cancelSavingToCRM"
        @save="saveConversationToCRM(summary.counterparts)"
      ></call-summary-counterpart-selection>
    </b-modal>

    <save-to-crm-success-screen
      v-if="showSaveSuccessfulScreen"
      :label="staticText.savedToCRMLabel"
      :counterpartsAvailable="counterpartsAvailable"
      :crmLogo="getCrmLogo(getCrmService)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import axios from "axios"
import CallSummaryCounterpartSelection from "@/apps/call_history/CallSummaryCounterpartSelection"
import ErrorModal from "../../base/ErrorModal.vue"
import SaveToCrmSuccessScreen from "./SaveToCrmSuccessScreen.vue"
import CrmLogoMixin from "./CrmLogoMixin"

export default {
  name: "SaveToCrmButton",
  mixins: [CrmLogoMixin],
  components: {
    CallSummaryCounterpartSelection,
    ErrorModal,
    SaveToCrmSuccessScreen
  },
  props: {
    summary: {
      type: Object,
      required: true
    },
    shouldSaveNotesToCrm: {
      type: Boolean,
      default: true
    },
    shouldSaveAISummaryToCrm: {
      type: Boolean,
      default: true
    },
    textSummary: {
      type: String,
      default: ""
    },
    callNotes: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      axios,
      counterpartsGroupedByType: [],
      showSavingToCRMConfirmation: false,
      savingToCRM: false,
      savingToCRMErrors: [],
      savedToCRM: false,
      showSaveSuccessfulScreen: false,
      staticTextDefault: {
        saveToCRMLabel: "Save to CRM",
        noCounterpartAvailableTooltip: "No counterpart selected, therefore cannot save to CRM",
        savingLabel: "Saving...",
        savedToCRMLabel: "Saved to CRM",
        errorSavingTooltipHoverTitle: "There were some errors saving the data to your CRM",
        errorText: "An error occurred",
        errorSavingCRM: "Error while saving to CRM",
        counterpartSelection: "Counterpart Selection",
        AISummaryLabel: "AI Summary",
        additionalNotesLabel: "Additional Notes",
        saveAndMinimizeLabel: "Save & Minimize"
      }
    }
  },
  computed: {
    ...mapGetters({
      canUseSaveToCrmFeature: "auth/canUseSaveToCrmFeature",
      getCallAudio: "callAudioStore/getCall",
      getCurrentSummaryTab: "callAudioStore/getCurrentSummaryTab",
      canUseBaoAudio: "auth/canUseBaoAudio",
      user: "auth/user",
      currentSummaryText: "callSummaryStore/getCurrentSummaryText"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    CRMErrorsExists () {
      return !!this.savingToCRMErrors && this.savingToCRMErrors.length > 0
    },
    showSaveToCRMBtn () {
      return !this.savingToCRM && !this.savedToCRM && !this.CRMErrorsExists && this.canUseSaveToCrmFeature
    },
    counterpartsAvailable () {
      return !!this.summary.counterparts && this.summary.counterparts.length > 0
    },
    getCrmService () {
      return this.summary.counterparts[0].crm_service
    },
    computedSaveToCrmLabel () {
      return this.isApplicationInIFrame ? this.staticText.saveAndMinimizeLabel : this.staticText.saveToCRMLabel
    },
    currentSummaryTab () {
      return this.getCurrentSummaryTab
    }
  },
  methods: {
    ...mapActions({
      fetchCallSummary: "callSummaryStore/fetchCallSummary"
    }),
    async checkSimilarCounterpartExists () {
      await this.fetchCallSummary({ callId: this.summary.id, showLoader: false })
      // group counterparts based on type and crm_service to get the number of counterparts for each group
      const groupedCounterparts = this.groupByTypeAndCRMService(this.summary.counterparts)
      // set flag 'save_crm_fields' for single typed counterparts to true
      groupedCounterparts.forEach(function (item) {
        item.counterparts[0].save_crm_fields = item.counterparts.length === 1
      })
      // get groups with multiple counterparts
      const groupsWithMultipleCounterparts = groupedCounterparts.filter(item =>
        item.counterparts.length > 1 && item.crm_service !== "unknown"
      )
      // check if summary has any answered call item and any of the answered call item contains crm linked field.
      const coveredLinkedFieldExists = this.coveredLinkedFieldsExist(this.summary)
      if (groupsWithMultipleCounterparts.length > 0 && coveredLinkedFieldExists) {
        // filter the groups with multiple counterparts whose corresponding crm field items were covered.
        const coveredLinkedFields = this.getCoveredLinkedFields(this.summary)
        const groupsWithCoveredLinkedFields = groupsWithMultipleCounterparts.filter(
          group => coveredLinkedFields.includes(group.crm_service + "-" + group.type)
        )
        // group the counterpart by type for displaying
        this.counterpartsGroupedByType = this.groupByType(groupsWithCoveredLinkedFields)
        if (this.counterpartsGroupedByType.length > 0) {
          this.showSavingToCRMConfirmation = true
        } else this.saveConversationToCRM(this.summary.counterparts)
      } else {
        this.saveConversationToCRM(this.summary.counterparts)
      }
    },
    groupByTypeAndCRMService (counterparts) {
      /***
       * Groups the counterparts based on its type and crm_service
       * Eg: [
       * { type:"Contact" , crm_service:"salesforce", crm_service_name:"Salesforce", counterparts:[] }
       * { type:"Contact" , crm_service:"hubspot", crm_service_name:"Hubspot", counterparts:[] }
       * ]
       */
      const groupedCounterparts = {}
      for (const i in counterparts) {
        const counterpart = counterparts[i]
        const key = counterpart.crm_service + "-" + counterpart.type
        if (!groupedCounterparts[key]) {
          groupedCounterparts[key] = {
            type: counterpart.type,
            crm_service: counterpart.crm_service,
            counterparts: []
          }
        }
        counterpart.save_crm_fields = false
        groupedCounterparts[key].counterparts.push(counterpart)
      }
      return Object.values(groupedCounterparts)
    },
    groupByType (groups) {
      /***
       * Groups the counterparts based on its type
       * Eg: [
       * { type:"Contact", counterparts:[] }
       * { type:"Lead", counterparts:[] }
       * ]
       */
      const groupedCounterparts = {}
      for (const i in groups) {
        const group = groups[i]
        if (!groupedCounterparts[group.type]) {
          groupedCounterparts[group.type] = {
            type: group.type,
            counterparts: []
          }
        }
        groupedCounterparts[group.type].counterparts.push(...group.counterparts)
      }
      return Object.values(groupedCounterparts)
    },
    coveredLinkedFieldsExist (summary) {
      /**
       * Opens counterpart selection modal if multiple counterparts exist for same type and crm service
       */
      if (summary.call_flow && Array.isArray(summary.call_flow)) {
        return summary.call_flow.some(item => !!item.call_item && !!item.call_item.linked_field)
      }
      return false
    },
    getCoveredLinkedFields (summary) {
      return summary.call_flow.map(item => {
        if (item.call_item && item.call_item.linked_field) {
          const crmObjectLink = item.call_item.linked_field.crm_object_link
          return crmObjectLink.service_key + "-" + crmObjectLink.object_type
        }
        return null
      })
    },
    saveConversationToCRM (counterparts) {
      this.showSavingToCRMConfirmation = false
      this.savingToCRM = true
      const that = this
      return new Promise((resolve, reject) => {
        const saveToCps = []
        const errors = []
        const url = "/api/counterpartsnew/log_a_call"
        for (const i in counterparts) {
          const cp = counterparts[i]
          const data = {
            counterpart: JSON.parse(JSON.stringify(cp)),
            call: that.summary.id,
            text: "",
            start_date_time: that.summary.date + " " + that.summary.time, // start time saved in Sugarcrm
            duration: that.summary.duration // (in milliseconds) call duration is saved in Sugarcrm, Hubspot, MSDynamics365 and Zoho.
          }
          data.text = this.getTextToSaveToCrm()
          let totalErrors = []
          saveToCps.push(this.axios.post(url, data).then(function (response) {
            totalErrors = that.checkForErrors(response.data)
          }).catch(error => {
            totalErrors = that.checkForErrors(error.response.data, true)
          }).finally(() => {
            if (totalErrors && totalErrors.length > 0) {
              that.$set(counterparts[i], "errors", totalErrors)
              errors.push(totalErrors)
            }
          }))
        }
        Promise.all(saveToCps).then(() => {
          that.savingToCRM = false
          that.savingToCRMErrors = errors
          if (!errors || errors.length === 0) {
            this.$emit("call-saved-to-crm")
            that.savedToCRM = true
            if (this.isApplicationInIFrame) {
              this.showSaveSuccessfulScreen = true
              setTimeout(() => {
                this.messageBaoSwift({ type: "callSavedToCrm" })
                this.showSaveSuccessfulScreen = false
              }, 1600)
            }
          }
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    getTextToSaveToCrm () {
      let text = ""
      if (this.shouldSaveNotesToCrm) {
        text = this.textSummary
        if (this.callNotes) {
          const callNotes = `${this.staticText.additionalNotesLabel}: ${this.callNotes}`
          text += text ? `\n${callNotes}\n` : `${callNotes}\n`
        }
      }
      if (this.shouldSaveAISummaryToCrm) {
        const callAISummary = this.currentSummaryText
        const summaryText = callAISummary ? `${this.staticText.AISummaryLabel}: ${callAISummary}` : ""
        if (summaryText) text += text ? `\n${summaryText}` : summaryText
      }

      const callLinkSuffix = this.canUseBaoAudio ? `/${this.summary.id}` : `?ids=${this.summary.id}`
      const baoCallLink = `${window.location.origin}/calls${callLinkSuffix}`
      const baoCallEntry = `Link to bao Call: ${baoCallLink} (${this.user.client_name})`

      text += text ? `\n${baoCallEntry}` : baoCallEntry
      return text
    },
    cancelSavingToCRM () {
      this.showSavingToCRMConfirmation = false
      this.counterpartsGroupedByType = []
    },
    checkForErrors (data, errorRaised = false) {
      // TODO: Can be made dynamic when errors are returned in similar way from the backend
      let errors = []
      if (data.log_a_call && data.log_a_call.errors) {
        errors = errors.concat(data.log_a_call.errors)
      }
      if (data.save_fields_to_crm && data.save_fields_to_crm.errors) {
        errors = errors.concat(data.save_fields_to_crm.errors)
      }
      if (data.errors_bao_value) {
        errors = errors.concat(data.errors_bao_value)
      }
      if (!data.log_a_call && !data.save_fields_to_crm && !data.errors_bao_value && errorRaised) {
        errors = errors.concat(data)
      }
      return errors
    }
  }
}
</script>

<style lang="scss" scoped>

.crm-save-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    max-height: 24px;
  }
}

</style>

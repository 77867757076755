<template>
  <div :class="['bao-widget-filter w-100 d-flex']">
    <bao-widget-filter-container
      :label="label"
      label-tag="base-filter-202309081357"
      :hasError="hasError"
    >
      <rich-text-editor
        :value="inputValue"
        :placeholder="placeholder"
        :disabled="disabled"
        :hasInnerLabel="true"
        @input="(value) => inputValue = value"
        @blur="onInputChange"
      ></rich-text-editor>
    </bao-widget-filter-container>
  </div>
</template>

<script>
import BaoWidgetFilterContainer from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/Filter/BaoWidgetFilterContainer.vue"
import RichTextEditor from "@/apps/richtexteditor/RichTextEditor.vue"

export default {
  name: "InnerLabelNotes",
  components: {
    BaoWidgetFilterContainer,
    RichTextEditor
  },
  data () {
    return {
      inputValue: this.value,
      inputValueChanged: false,
      hasError: false
    }
  },
  props: {
    value: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  watch: {
    inputValue () {
      this.inputValueChanged = true
    }
  },
  methods: {
    onInputChange () {
      if (this.inputValueChanged) {
        this.$emit("input", this.inputValue)
        this.inputValueChanged = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.form-control {
  background-color: $white80;
  padding: 20px 16px 8px;
  color: $slate;
  resize: none;

  &:disabled {
    background-color: rgba(255, 255, 255, 0.4);
    color: rgba(127, 129, 151, 1);
  }
}

</style>

<template>
  <div class="call-flow-empty">
    <div
      v-if="activeFilter === UNANSWERED_FILTER"
      class="d-flex flex-column align-items-center"
    >
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'mb-3'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M2.29 9.581c-.987 0-1.79.794-1.79 1.768v5.658c0 .975.803 1.768 1.79 1.768h2.148c.403 0 .774-.134 1.074-.357V9.58H2.29zM19.248 12.11c0-.486-.192-.938-.526-1.272.377-.413.57-.966.517-1.543-.094-1.026-1.02-1.831-2.107-1.831h-4.99c.246-.75.642-2.127.642-3.232C12.784 2.479 11.295 1 10.36 1c-.84 0-1.439.473-1.464.492a.406.406 0 00-.152.316v2.74l-2.327 5.04-.096.05v8.66c.657.31 1.49.477 2.02.477h7.416c.88 0 1.65-.593 1.83-1.411a1.818 1.818 0 00-.145-1.214 1.81 1.81 0 00.808-2.424c.597-.301.998-.916.998-1.616z" fill="#AAABBA"/></svg>
      {{ staticText.allItemsAnswered }}
    </div>
    <div v-else-if="activeFilter === ANSWERED_FILTER">
      {{ staticText.allItemsUnansweredLabel }}
      <span
        role="button"
        class="call-flow-link"
        @click="$emit('set-unanswered-filter')"
      >{{ staticText.allItemsUnansweredAction }}</span>
    </div>
    <div v-else>
      {{ staticText.defaultEmptyText }}
    </div>
  </div>
</template>

<script>
import { ANSWERED_FILTER, UNANSWERED_FILTER } from "./constants.js"

export default {
  name: "CallFlowItemsEmptyState",
  data () {
    return {
      ANSWERED_FILTER,
      UNANSWERED_FILTER,
      staticTextDefault: {
        allItemsAnswered: "Yaih, seems like all items are answered",
        allItemsUnansweredLabel: "Seems like you didn’t answer any item yet -",
        allItemsUnansweredAction: "go answer them",
        defaultEmptyText: "This view is empty, please adjust the filter"
      }
    }
  },
  props: {
    activeFilter: {
      type: String,
      default: ""
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  }
}
</script>

<style lang="scss" scoped>

.call-flow-empty {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $slate40;
  .call-flow-link {
    font-style: italic;
    &:hover {
      text-decoration: underline;
    }
  }
}

</style>

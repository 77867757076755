<template>
  <div>

    <div v-if="!hideTitle" class="d-flex mb-4">
      <slot name="title"></slot>
    </div>

    <!-- User Interface controls -->
    <div v-if="!hideFilterControl"
         class="d-flex align-items-center justify-content-between"
    >

      <div class="d-flex align-items-center">
        <div class="d-flex">
          <b-button-toolbar
            key-nav
            aria-label="Toolbar with button groups"
          >
            <b-button-group class="history">
              <div class="search-bar" v-if="!advancedSearchActive">
                <b-form-input id="filter-search-input-202012311516"
                              v-model="filter"
                              :placeholder="staticText.searchPlaceholder"
                              debounce="300"
                ></b-form-input>
                <span class="search-icon">
                  <img src="../../../public/img/icons/search-icon.svg"/>
                </span>
                <span class="search-label">
                  {{ staticText.searchLabel }}
                </span>
                <span
                  :class="['cancel-icon', { 'show' : filter }]"
                  @click="resetFilter">
                  <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="a" fill="#fff"><path d="M7 .705L6.295 0 3.5 2.795.705 0 0 .705 2.795 3.5 0 6.295.705 7 3.5 4.205 6.295 7 7 6.295 4.205 3.5 7 .705z"/></mask><path d="M7 .705L6.295 0 3.5 2.795.705 0 0 .705 2.795 3.5 0 6.295.705 7 3.5 4.205 6.295 7 7 6.295 4.205 3.5 7 .705z" fill="#000" fill-opacity=".5"/><path d="M7 .705l.707.707.707-.707-.707-.707L7 .705zM6.295 0l.707-.707-.707-.707-.707.707.707.707zM3.5 2.795l-.707.707.707.707.707-.707-.707-.707zM.705 0l.707-.707-.707-.707-.707.707L.705 0zM0 .705l-.707-.707-.707.707.707.707L0 .705zM2.795 3.5l.707.707.707-.707-.707-.707-.707.707zM0 6.295l-.707-.707-.707.707.707.707L0 6.295zM.705 7l-.707.707.707.707.707-.707L.705 7zM3.5 4.205l.707-.707L3.5 2.79l-.707.707.707.707zM6.295 7l-.707.707.707.707.707-.707L6.295 7zM7 6.295l.707.707.707-.707-.707-.707L7 6.295zM4.205 3.5l-.707-.707-.707.707.707.707.707-.707zM7.707-.002l-.705-.705L5.588.707l.705.705L7.707-.002zm-2.12-.705L2.794 2.088l1.414 1.414L7.002.707 5.588-.707zm-1.38 2.795L1.412-.707-.002.707l2.795 2.795 1.414-1.414zM-.003-.707l-.704.705L.707 1.412l.705-.705L-.002-.707zm-.704 2.12l2.795 2.794 1.414-1.414L.707-.002-.707 1.412zm2.795 1.38L-.707 5.588.707 7.002l2.795-2.795-1.414-1.414zm-2.795 4.21l.705.704 1.414-1.414-.705-.705-1.414 1.414zm2.12.704l2.794-2.795-1.414-1.414-2.795 2.795 1.414 1.414zm1.38-2.795l2.795 2.795 1.414-1.414-2.795-2.795-1.414 1.414zm4.21 2.795l.704-.705-1.414-1.414-.705.705 1.414 1.414zm.704-2.12L4.912 2.794 3.498 4.207l2.795 2.795 1.414-1.414zm-2.795-1.38l2.795-2.795L6.293-.002 3.498 2.793l1.414 1.414z" fill="#2A2D52" fill-opacity=".01" mask="url(#a)"/></svg>
                </span>
              </div>
              <slot v-else name="advancedSearchAction"></slot>
              <b-btn
                v-if="showAdvancedFilterControl"
                class="mt-2 ml-2 p-0 pt-1 bg-transparent filter-toggle-btn"
                @click="$emit('show-advanced-search', !advancedSearchActive)"
              >
                {{ staticText.searchLabel }}
                <i class="mx-2 fas fa-2x fa-toggle-on text-slate-80"
                   :class="{'flipped': !advancedSearchActive}"
                />
                {{ staticText.filterLabel }}
              </b-btn>
            </b-button-group>
          </b-button-toolbar>
        </div>

        <slot name="leftListActions"></slot>
      </div>

      <slot name="listActions"></slot>
    </div>

    <!-- Main table element -->
    <div class="table-wrapper">
      <div class="mt-3 table-responsive">
        <b-table id="base-list-table-202012311516"
                 v-model="displayedItems"
                 :hover="hover"
                 :items="items"
                 :fields="fields"
                 :current-page="currentPage"
                 :per-page="0"
                 :filter="asyncFilter ? null : filter"
                 :sort-by.sync="sortBy"
                 :sort-desc.sync="sortDesc"
                 :sort-direction.sync="sortDirection"
                 :busy="loading"
                 :empty-text="staticText.noRecordsMsg"
                 :no-local-sorting="noLocalSortProp"
                 show-empty
                 @filtered="onFiltered"
        >

          <template v-for="(slot, index) in slots"
                    v-slot:[`head(${slot.head})`]="data"
          >
            <div :key="index">
              <slot :name="'head_' + slot.head"
                    :cell="data.item"
              ></slot>
            </div>
          </template>

          <template v-for="(slot, index) in slots"
                    v-slot:[`cell(${slot.field})`]="data"
          >
            <div :key="index">
              <slot :name="slot.field" :cell="data.item" :index="data.index"></slot>
            </div>
          </template>

          <div id="table-busy-slot-202101031520"
               slot="table-busy"
               class="text-center text-primary my-2"
          >
            <progress-indicator :loading-label="staticText.loadingLabel"></progress-indicator>
          </div>

          <div id="table-empty-slot-202101031520"
               slot="empty"
               class="text-center my-2 empty-table"
          >
            <div class="empty-table-icon">
              <i class="fas fa-search"></i>
            </div>
            <div class="empty-table-title">
              {{ staticText.noItemsFeedback }}
            </div>
            <div class="empty-table-subtitle">
              {{ staticText.noItemsFeedbackSubtitle }}
            </div>
          </div>

          <template v-slot:custom-foot>

            <tr v-if="showPagination">
              <td :colspan="fields.length">
                <div class="row">

                  <div v-if="totalItems > 0"
                       class="mx-auto pagination"
                  >
                    <b-pagination id="pagination-202101031520"
                                  v-model="currentPage"
                                  :total-rows="totalItems"
                                  :per-page="page_size"
                                  class="p-1 m-0"
                                  prev-text="<"
                                  next-text=">"
                                  first-number
                                  last-number
                                  @change="pageChange"
                    ></b-pagination>
                  </div>
                </div>
              </td>
            </tr>

          </template>

        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressIndicator from "./ProgressIndicator"

export default {
  name: "BaseList",
  components: {
    ProgressIndicator
  },
  props: {
    noLocalSortProp: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => {
        return []
      }
    },
    defaultSortBy: {
      type: String,
      default: "id"
    },
    fields: {
      type: Array,
      default: () => {
        return [
          { key: "id", label: "Item ID", sortable: true, sortDirection: "desc" }
        ]
      }
    },
    slots: {
      type: Array,
      required: false
    },
    getDisplayText: {
      type: Function,
      default: (item) => {
        return "Item ID: " + item.id
      }
    },
    sortByProp: {
      type: String,
      default: null
    },
    sortDescProp: {
      type: Boolean,
      default: false
    },
    totalItems: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: true
    },
    hideFilterControl: {
      type: Boolean,
      default: false
    },
    showAdvancedFilterControl: {
      type: Boolean,
      default: false
    },
    asyncFilter: {
      type: Function,
      default: null
    },
    hover: {
      type: Boolean,
      default: false
    },
    showPagination: {
      type: Boolean,
      default: true
    },
    hideTitle: {
      type: Boolean,
      default: false
    },
    advancedSearchActive: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      location,
      pageOptions: [5, 10, 20, 50],
      sortBy: null,
      currentPage: 1,
      page_size: 10,
      sortDesc: false,
      sortDirection: "desc",
      filter: null,
      displayedItems: [],
      staticTextDefault: {
        filterLabel: "Filter",
        searchLabel: "Search",
        searchPlaceholder: "Type to search",
        clearBtn: "Clear",
        loadingLabel: "Loading...",
        noItemsFeedback: "No Items to Display",
        noItemsFeedbackSubtitle: "Try again with different search parameters",
        itemsPerPageLabel: "Items per page",
        noRecordsMsg: "There are no records to show."
      }
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  watch: {
    filter (val) {
      if (this.asyncFilter) {
        this.asyncFilter(val)
      }
    },
    sortBy (val) {
      this.$emit("sortingChanged", val, this.sortDesc)
    },
    sortDesc (val) {
      this.$emit("sortingChanged", this.sortBy, val)
    },
    "$route.query.currentPage" () {
      this.currentPage = this.$route.query.currentPage
    }
  },
  created () {
    this.sortBy = this.sortByProp || this.defaultSortBy
    this.sortDesc = this.sortDescProp
  },
  mounted () {
    this.currentPage = this.$route.query.currentPage ? this.$route.query.currentPage : 1
    this.page_size = this.$route.query.page_size ? this.$route.query.page_size : 10
  },
  methods: {
    pageChange (page) {
      this.$emit("page-changed", page)
    },
    execAsyncFilter () {
      this.asyncFilter(this.filter)
    },
    resetFilter () {
      this.filter = ""
      this.$emit("filterReset")
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.$emit("filter-changed", filteredItems)
    }
  }
}
</script>

<style scoped lang="scss">

.search-bar {
  position: relative;
  width: 200px;

  .search-label {
    color: $slate40;
    position: absolute;
    top: 4px;
    left: 16px;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    font-size: 12px;
  }

  input:not(:placeholder-shown) ~ .search-label {
    bottom: 100%;
    opacity: 1;
    visibility: visible;
  }
}

.history {
  z-index: 999;

  input {
    font-size: 16px;
    background-color: $white40;
    box-shadow: 14px 12px 40px rgba(199, 199, 199, 0.12);
    border: none;
    border-radius: 12px;
    padding: 16px 40px 16px 40px;
  }

  .search-icon {
    color: $slate40;
    position: absolute;
    display: flex;
    align-items: center;
    top: 18px;
    left: 16px;
  }

  .cancel-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    position: absolute;
    top: 20px;
    right: 16px;
    border-radius: 50%;
    background-color: $grey;
    cursor: pointer;
    opacity: 0;
    transition: 0.3s ease-in;

    svg {
      height: 10px;
      width: 10px;
    }

    &.show {
      opacity: 1;
    }
  }
}

.empty-table {
  height: calc(100vh - 312px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.empty-table-icon {
  font-size: 24px;
  padding: 29px;
  margin-bottom: 24px;
  width: 82px;
  height: 82px;
  color: $slate40;
  background-color: $slate05;
  border-radius: 50%;
}

.empty-table-title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  color: $slate;
}

.empty-table-subtitle {
  font-size: 16px;
  line-height: 19px;
  color: $slate;
}

.filter-toggle-btn {
  position: absolute;
  display: flex;
  align-items: center;
  left: 200px;

  .flipped {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
</style>

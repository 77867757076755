<template>
  <div class="editor-container">
    <b-card>
      <template #header>
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-start">
            {{ staticText.headerText }}
            <img src="/img/icons/info-icon.svg"
                 class="ml-1 cursor-pointer info-icon"
                 v-b-tooltip.hover.bottom
                 :title="staticText.headerTooltipText"
            >
          </div>
          <div>
            <div type="button"
                 @click="closeEditor"
            >
              <img src="@/assets/svgs/close-circle.svg">
            </div>
          </div>

        </div>
      </template>
      <b-card-text>
        <!--   email recipients     -->
        <bao-vue-multi-select
          v-model="email.selectedRecipients"
          :clear-on-select="false"
          :close-on-select="false"
          :internal-search="false"
          :loading="loading"
          :multiple="true"
          :options="emailRecipientOptions"
          :placeholder="staticText.searchPlaceholder"
          :show-labels="false"
          class="form-control__custom"
          label="email"
          track-by="crm_id"
          @search-change="searchTerm => asyncSearch(searchTerm)"
          @input="input => validateInput(input)"
        >
          <template #placeholder>
            {{ staticText.sendTo }}
          </template>

          <template #noResult>
            {{ staticText.noResultMessage }}
          </template>

          <template #noOptions>
            {{ staticText.noOptionsMessage }}
          </template>
        </bao-vue-multi-select>

        <!--   email subject     -->
        <b-form-input
          v-model="email.emailSubject"
          :placeholder="staticText.subject"
          class="form-control__custom"
        />

        <!--   email body     -->
        <b-form-textarea
          id="textarea"
          v-model="email.emailText"
          :placeholder="staticText.text"
          class="form-control__custom"
          max-rows="50"
          rows="8"
        />
      </b-card-text>

      <!--   send email button     -->
      <b-button class="float-right send-email-button"
                variant="primary"
                @click="sendEmail"
      >
        <progress-indicator
          v-if="sendInProgress"
          :show-loading-label="false"
          :small="true"
        ></progress-indicator>
        <span v-else>{{ staticText.sendBtnLabel }}</span>
      </b-button>
    </b-card>
  </div>
</template>

<script>
import BaoVueMultiSelect from "@/apps/base/BaoVueMultiSelect"
import axios from "axios"
import { mapActions, mapGetters } from "vuex"
import ProgressIndicator from "@/apps/base/ProgressIndicator"
import { findCRMLookupFieldValueById } from "@/apps/call/utils"

export default {
  name: "EmailEditor",
  components: {
    ProgressIndicator,
    BaoVueMultiSelect
  },
  data () {
    return {
      axios,
      staticTextDefault: {
        headerText: "New E-Mail",
        sendBtnLabel: "Send E-Mail",
        sendTo: "To",
        subject: "Subject",
        text: "Text",
        emailSentTitle: "E-Mail sent",
        emailSentDescription: "Your E-Mail was sent successfully.",
        emailErrorTitle: "Error",
        emailErrorDescription: "Some error occurred, please try again.",
        invalidInputTitle: "Invalid input",
        invalidInputDescription: "Missing required inputs.",
        searchPlaceholder: "Type to search",
        noResultMessage: "No results found. Consider changing the search query and make sure to provide at least two characters.",
        noOptionsMessage: "No recipients available.",
        headerTooltipText: "This E-Mail will be sent via your CRM-integration, not via bao.",
        tooManyRecipientsTitle: "Too many recipients",
        tooManyRecipientsDescription: "You can only add up to 5 recipients.",
        invalidEmailTitle: "Invalid email address format",
        invalidEmailText: "Please enter a valid email address."
      },
      email: {
        selectedRecipients: [],
        emailSubject: null,
        emailText: null
      },
      errors: null,
      sendInProgress: false
    }
  },
  props: {
    preSelectedRecipients: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  watch: {
    preSelectedRecipients: {
      handler (val) {
        if (!!val && val.length > 0) {
          this.processPreselectedRecipients(val)
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters({
      searchedCounterpartOptions: "counterpartStore/getCounterpartOptions",
      loading: "counterpartStore/getLoading"
    }),
    emailRecipientOptions () {
      return [...this.preSelectedRecipients, ...this.searchedCounterpartOptions].filter(item => !!item.email)
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    noRecipients () {
      return this.email.selectedRecipients.length === 0
    },
    noSubject () {
      return !this.email.emailSubject
    },
    noText () {
      return !this.email.emailText
    },
    salesforceRecipientsMaxCount () {
      if (this.email.selectedRecipients.length > 5) {
        return this.email.selectedRecipients.at(-1).crm_service === "salesforce"
      }
      return false
    }
  },
  methods: {
    ...mapActions({
      asyncSearch: "counterpartStore/asyncSearch"
    }),
    processPreselectedRecipients (recipients) {
      recipients.forEach(async recipient => {
        // Special case for MS Dynamics Opportunities - do not have email attribute
        if (recipient.crm_service === "dynamics365" && recipient.type === "opportunity") {
          // retrieve related contact's email and set as opportunity's email
          const contactCrmId = recipient.external_resource._parentcontactid_value
          if (contactCrmId) {
            const relatedContact = await findCRMLookupFieldValueById(
              "dynamics365",
              "contact",
              contactCrmId
            )
            if (relatedContact) {
              recipient = relatedContact
            }
          }
        }

        if (recipient.email && this.isEmailValidOrEmpty(recipient.email)) {
          this.email.selectedRecipients.push(recipient)
        }
      })
    },
    isEmailValidOrEmpty (email) {
      return !email || this.isEmailValid(email)
    },
    validateInput (inputValue) {
      if (inputValue.length > 0) {
        if (!this.isEmailValidOrEmpty(inputValue.at(-1).email)) {
          this.showGlobalToast({
            status: "error",
            message: {
              title: this.staticText.invalidEmailTitle,
              description: this.staticText.invalidEmailText
            }
          })
        }
      }

      if (this.salesforceRecipientsMaxCount) {
        this.showGlobalToast({
          status: "error",
          message: {
            title: this.staticText.tooManyRecipientsTitle,
            description: this.staticText.tooManyRecipientsDescription
          }
        })
      }
    },
    closeEditor () {
      this.$emit("closed")
    },
    showInputErrors () {
      let invalidMessage = {
        title: this.staticText.invalidInputTitle,
        description: this.staticText.invalidInputDescription
      }
      if (this.salesforceRecipientsMaxCount) {
        invalidMessage = {
          title: this.staticText.tooManyRecipientsTitle,
          description: this.staticText.tooManyRecipientsDescription
        }
      }
      if (this.noRecipients || this.noSubject || this.noText || this.salesforceRecipientsMaxCount) {
        this.showGlobalToast({ status: "error", message: invalidMessage })
        return true
      }
      return false
    },
    sendEmail () {
      if (this.showInputErrors()) return
      const successMessage = {
        title: this.staticText.emailSentTitle,
        description: this.staticText.emailSentDescription
      }
      const errorMessage = {
        title: this.staticText.emailErrorTitle,
        description: this.staticText.emailErrorDescription
      }
      const crmService = this.email.selectedRecipients[0].crm_service
      this.sendInProgress = true
      return new Promise((resolve, reject) => {
        const URL = "/api/oauthservices/" + crmService + "/send_email"
        this.axios.post(URL, { email: this.email }).then(response => {
          if (response.data.errors) {
            this.showGlobalToast({ status: "error", message: errorMessage })
          } else {
            this.showGlobalToast({ status: "success", message: successMessage })
            this.closeEditor()
          }
          resolve(response.data)
          this.sendInProgress = false
        }).catch(error => {
          this.showGlobalToast({ status: "error", message: errorMessage })
          this.sendInProgress = false
          reject(error)
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.editor-container {
  background-color: $white80;
  border-radius: 20px;
}

.form-control__custom {
  margin-bottom: 20px;
  border: 1px solid #D4D5DC;
  border-radius: 12px;

}

:deep(.card) {
  border-radius: 12px !important;

  .card-header {
    border-radius: 12px 12px 0 0 !important;
  }
}

.send-email-button {
  min-width: 116px;
  min-height: 56px;
}

.info-icon {
  color: $slate80;
  width: 16px;
  margin-top: 1px;
}
</style>

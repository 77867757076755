const CallItemMixin = {
  name: "CallItemMixin",
  computed: {
    singleSelectItemTypes () {
      return ["single_select_radio", "single_select_dropdown"]
    },
    multiSelectItemTypes () {
      return ["question", "rated_multiselect", "multi_select_dropdown"]
    }
  },
  methods: {
    isItemType (item, type) {
      return item.item_type && item.item_type === type
    },
    isItemWithAnswers (itemType) {
      const itemTypesWithAnswers = [...this.singleSelectItemTypes, ...this.multiSelectItemTypes]
      return itemTypesWithAnswers.includes(itemType)
    },
    isItemSingleSelect (itemType) {
      return this.singleSelectItemTypes.includes(itemType)
    },
    hasObjections (item) {
      return item.objections && !!item.objections.length
    },
    showNotesField (item) {
      return item.note
    },
    loadedADCs (item) {
      return item.loadedData && !!item.loadedData.length ? item.loadedData : null
    },
    hasChildren (item) {
      return item.selected_choice.workflow && item.selected_choice.workflow.children
    },
    getChildren (item) {
      return item.selected_choice.workflow.children
    },
    getAnswerChoices (item) {
      return item.answer_choices
    },
    getFieldDefinitions (item) {
      return item.linked_field && item.linked_field.field_definition ? item.linked_field.field_definition : []
    },
    hasAnswers (item) {
      return item.call_item && !!item.call_item.answers.length
    },
    hasAnswerOptions (item) {
      return this.isItemWithAnswers(item.item_type) || !!this.getFieldDefinitions(item).length
    },
    canHaveAIAnswers (item) {
      return this.isItemWithAnswers(item.item_type) || this.isItemType(item, "crmlink")
    }
  }
}

export default CallItemMixin

<template>
  <bao-vue-multi-select
    :value="selectedValue"
    :options="allValues"
    :searchable="searchEnabled"
    :loading="isLoading"
    label="name"
    track-by="crm_id"
    :show-labels="false"
    :internal-search="false"
    :placeholder="placeholderText"
    :disabled="disabled"
    @search-change="findLookupValues"
    @open="findLookupValues"
    @input="inputData => handleInputChange(inputData)"
  >
    <template #noResult>
      {{ staticText.noResultsText }}
    </template>

    <template #noOptions>
      {{ staticText.listEmptyText }}
    </template>
  </bao-vue-multi-select>
</template>

<script>
import axios from "axios"
import BaoVueMultiSelect from "@/apps/base/BaoVueMultiSelect"
import { findCRMLookupFieldValueById } from "@/apps/call/utils.js"

export default {
  name: "CrmLookupFieldInput",
  components: { BaoVueMultiSelect },
  props: {
    linkedField: {
      type: Object,
      required: true
    },
    fieldDefinition: {
      type: Object,
      required: true
    },
    value: {
      type: [String, Number, Boolean, Array, Object, Date],
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedValue: [],
      allValues: [],
      isLoading: false,
      staticTextDefault: {
        listEmptyText: "Search to load available options.",
        noResultsText: "No results found.",
        searchPlaceholder: "Type to search",
        selectOptionPlaceholder: "Select option"
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (newValue, oldValue) {
        if (newValue === null && oldValue !== undefined) {
          // clear selected value if downloaded data is cleared.
          this.selectedValue = null
          this.handleInputChange(this.selectedValue)
          return
        }
        if (newValue) {
          this.transformDownloadedValue(newValue).then(value => {
            this.selectedValue = value
            if (value !== newValue) {
              this.handleInputChange(this.selectedValue)
            }
          })
        }
      }
    }

  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    isCounterpart () {
      return this.linkedField.crm_object_link.is_counterpart
    },
    searchEnabled () {
      return this.isCounterpart
    },
    placeholderText () {
      return this.searchEnabled ? this.staticText.searchPlaceholder : this.staticText.selectOptionPlaceholder
    }
  },
  methods: {
    getAnswerFields (crmObject) {
      /**
       * It is necessary to save only limited/necessary fields in database as answer's text has character limit.
       * **/
      return {
        name: crmObject.name ? crmObject.name : crmObject.label,
        type: crmObject.type,
        crm_id: crmObject.crm_id ? crmObject.crm_id : crmObject.value,
        crm_service: crmObject.crm_service
      }
    },
    async transformDownloadedValue (downloadedValue) {
      /**
       * Note: if value is object type and already transformed then just return it.
       * otherwise, it could just be the crm_id of the object. hence, we need to retrieve the whole object with this id
       * directly from CRM to access other attributes as well for display purpose.
       * */
      if (typeof downloadedValue === "string") {
        downloadedValue = await this.findLookupValueById(downloadedValue)
        downloadedValue = this.getAnswerFields(downloadedValue)
      }
      return downloadedValue
    },
    handleInputChange (inputData) {
      const payload = inputData ? this.getAnswerFields(inputData) : null
      this.$emit("input", payload)
    },
    async findLookupValueById (crmId) {
      try {
        this.isLoading = true
        const crmService = this.linkedField.crm_object_link.service_key
        const data = await findCRMLookupFieldValueById(crmService, this.fieldDefinition.lookup_targets[0], crmId)
        this.isLoading = false
        return data
      } catch (error) {
        this.isLoading = false
      }
    },
    async findLookupValues (query) {
      try {
        this.isLoading = true
        const crmService = this.linkedField.crm_object_link.service_key
        const url = `/api/oauthservices/${crmService}/get_lookup_values/`
        const { data } = await axios.get(url, {
          params: {
            object_type: this.linkedField.crm_object_link.object_type,
            crm_field: this.fieldDefinition,
            query
          }
        })
        this.allValues = data.results.map(item => this.getAnswerFields(item))
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
      }
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div>
    <b-form-group v-for="(inputKey, index) in inputKeys"
                  :key="index"
                  :label="inputKey"
                  label-cols="4"
                  label-class="pt-3"
    >
      <b-form-input
        v-model="newValue[inputKey]"
        @input="updateValue">
      </b-form-input>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: "BaseFormGroupInput",
  props: {
    inputKeys: {
      type: Array,
      required: true
    },
    value: {
      required: false,
      default: null
    }
  },
  data () {
    return {
      newValue: {}
    }
  },
  watch: {
    value: {
      handler (newVal) {
        this.newValue = { ...this.newValue, ...newVal }
      },
      immediate: true
    }
  },
  methods: {
    updateValue () {
      this.$emit("input", this.newValue)
    }
  }

}
</script>

<style scoped lang="scss">

</style>

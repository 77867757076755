<template>
  <div class="w-100">
    <div class="d-flex justify-content-between">

      <div id="baovaluelabel"
           class="col my-auto"
      >
        bao value (Highscore):
      </div>

      <div id="baovaluehover">
        <b-btn variant="primary"
               disabled
        >
          {{baoValues[0].value}} / {{baoValues[0].max_value}}
        </b-btn>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "BaoValuePopover",
  props: {
    baoValues: {
      type: Array,
      default: () => [],
      required: true
    }
  }
}
</script>

<style scoped lang="scss">

</style>
